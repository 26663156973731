import { render, staticRenderFns } from "./total.vue?vue&type=template&id=10c203ec&scoped=true&"
import script from "./total.vue?vue&type=script&lang=js&"
export * from "./total.vue?vue&type=script&lang=js&"
import style0 from "./total.vue?vue&type=style&index=0&id=10c203ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c203ec",
  null
  
)

export default component.exports