<template>
  <div class="about">
    <div class="pc">
      <div class="banner">
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">About Kenta</div>
          <div class="box" v-show="this.$store.state.sw">關於</div>
        </div>
      </div>
      <div class="jar">
        <h3 v-show="!this.$store.state.sw">Milestone</h3>
        <h3 v-show="this.$store.state.sw">里程碑</h3>
        <div class="xiu"></div>
        <br /><br />
        <el-carousel
          :interval="5000"
          type="card"
          height="13vw"
          indicator-position="none"
        >
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>1985</h3>
              </div>
              <div class="cont">
                <img style="width: 40%" src="img/l1.png" alt="" />
                <p v-show="!this.$store.state.sw">
                  Kenta Enterprise Ltd Co. was founded in Hong Kong.
                </p>
                <p v-show="this.$store.state.sw">
                  健大企業有限公司于於香港成立。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>1991</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 35%; height: 5vw"
                  src="img/zbj4.png"
                  alt=""
                />
                <img style="width: 35%; height: 5vw" src="img/l2.png" alt="" />
                <p v-show="!this.$store.state.sw">
                  The first factory was established in Dong Guan, Guang Dong,
                  targeting at the market in traditional transformers.
                </p>
                <p v-show="this.$store.state.sw">
                  新工廠於廣東東莞建立,專注於傳統變壓器市場。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>1998</h3>
              </div>
              <div class="cont">
                <img style="width: 16%; height: 5vw" src="img/l3.png" alt="" />
                <img
                  style="width: 16%; height: 5vw"
                  src="img/l3_2.png"
                  alt=""
                />
                <img
                  style="width: 30%; height: 5vw"
                  src="img/l3_3.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  Dong Guan factory was awarded ISO 9001 certificate & UL
                  accreditation. The factory developed a new market in SMD
                  transformers.
                </p>
                <p v-show="this.$store.state.sw">
                  東莞健大通過ISO 9001 & UL 認證。開發SMD類變壓器。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2000</h3>
              </div>
              <div class="cont">
                <img style="width: 35%; height: 5vw" src="img/l4.png" alt="" />
                <img
                  style="width: 30%; height: 5vw"
                  src="img/l4_2.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  A new factory complex was established in Dong Guan. The
                  factory developed a new market in automotive products and
                  began to develop and manufacture igniters for Ford.
                </p>
                <p v-show="this.$store.state.sw">
                  東莞建立花園式新廠區。開發汽車零件類產品。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2001</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 35%; height: 5vw"
                  src="img/l5_1.png"
                  alt=""
                />
                <img
                  style="width: 35%; height: 5vw"
                  src="img/l5_2.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  The company stepped into the market in network and connector
                  products.
                </p>
                <p v-show="this.$store.state.sw">開發網絡連接器類產品。</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2003</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 18%; height: 5vw"
                  src="img/l3_2.png"
                  alt=""
                />
                <img
                  style="width: 18%; height: 5vw"
                  src="img/l6_2.png"
                  alt=""
                />
                <img style="width: 35%; height: 5vw" src="img/t1.png" alt="" />
                <p v-show="!this.$store.state.sw">
                  The company stepped into the market in network and connector
                  products. The company started to build a new and larger
                  factory complex in Kun Shan, Jiangsu.
                </p>
                <p v-show="this.$store.state.sw">
                  通過SONY GP and IOS 14000認證。
                  開始於江蘇昆山建立更大的花圜式新工廠
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2005</h3>
              </div>
              <div class="cont">
                <img style="width: 35%; height: 5vw" src="img/l8.png" alt="" />
                <img
                  style="width: 18%; height: 5vw"
                  src="img/l8_1.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  Kun Shan Factory was estab-lished to focus on developing and
                  manufacturing automotive products.Dong Guan Factory obtained
                  ISO TS16949 certification.
                </p>
                <p v-show="this.$store.state.sw">
                  昆山工廠成立專注於研發和生産汽事產品。東莞工廠通過 IOS TS16949
                  認證。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2006</h3>
              </div>
              <div class="cont">
                <img style="width: 16%; height: 5vw" src="img/l7.png" alt="" />
                <img
                  style="width: 27%; height: 5vw"
                  src="img/l7_1.png"
                  alt=""
                />
                <img
                  style="width: 35%; height: 5vw"
                  src="img/l7_2.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  Kun Shan Factory obtained UL & ISO certifications. The factory
                  developed a new market in network communications products.
                </p>
                <p v-show="this.$store.state.sw">
                  昆山工廠通過UL&ISO認證。開發網絡通訊類產品，
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2008</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 16%; height: 5vw"
                  src="img/l9_1.png"
                  alt=""
                />
                <img
                  style="width: 16%; height: 5vw"
                  src="img/l9_2.png"
                  alt=""
                />
                <img
                  style="width: 30%; height: 5vw"
                  src="img/l9_3.png"
                  alt=""
                />
                <img
                  style="width: 30%; height: 5vw"
                  src="img/l9_4.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  Kun Shan Factory was awarded ISO 14000 & TS 16949
                  certifica-tions. Dong Guan Factory was qualified as a second
                  tier supplier for Volkswagen and BMW.
                </p>
                <p v-show="this.$store.state.sw">
                  昆山工廠通過ISO 14000 & TS16949。
                  廣東東莞工廠獲得大眾(VM)和寶馬(BMW)的二級供應商資格。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2009</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 30%; height: 5vw"
                  src="img/l10_1.png"
                  alt=""
                />
                <img
                  style="width: 50%; height: 5vw"
                  src="img/l10_2.png"
                  alt=""
                />
                <p v-show="!this.$store.state.sw">
                  Dong Guan Factory began to develop and manufacture electronic
                  control systems and molds for Volkswagen.
                </p>
                <p v-show="this.$store.state.sw">
                  東莞工廠參與研發及生產大眾汽車(VM)之電子控制系統之產品和模具
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2011</h3>
              </div>
              <div class="cont">
                <img style="width: 30%" src="img/l11.png" alt="" />
                <p v-show="!this.$store.state.sw">
                  The company started to develope low-voltage electrical
                  products, and BMC products and molds.
                </p>
                <p v-show="this.$store.state.sw">
                  開發了低壓電器類產品並研發和生產BMC(團狀模塑料)類產品和模具
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2013</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 25%; height: 4vw"
                  src="img/l14_1.png"
                  alt=""
                />
                <img
                  style="width: 25%; height: 4vw"
                  src="img/l14_2.png"
                  alt=""
                />
                <img
                  style="width: 18%; height: 4vw"
                  src="img/l14_4.png"
                  alt=""
                />
                <img
                  style="width: 18%; height: 4vw"
                  src="img/l14_5.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw">
                  A professional automation R&D team was established. The team
                  began to develop and manufacture automo-tive ignition switch
                  molds, one-port molds, remote key molds, and etc. for
                  SAIC-GM-Wuling Automobile.
                </p>
                <p v-show="this.$store.state.sw">
                  建立起專業的自動化研發團隊。
                  開始為上汽通用五菱配套研發並生產汽車點火開關模組、一鍵啟動模組、遙控鑰匙模組。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2014</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 40%; height: 7vw"
                  src="img/l15_1.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw">
                  Establish in House Stamping workshop in Kun Shan.
                </p>
                <p v-show="this.$store.state.sw">昆山厂成立五金冲压车间。</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2015</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 40%; height: 7vw"
                  src="img/l16_1.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw">
                  Stepped into medical Device Market.
                </p>
                <p v-show="this.$store.state.sw">
                  东莞厂成立 专注于传统骨架市场。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2016</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 40%; height: 7vw"
                  src="img/l17_1.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw">
                  The first fully automated production line MP. Total solutions
                  from injection molding to robotic assembl
                </p>
                <p v-show="this.$store.state.sw">
                  首条全自动生产线投入量产
                  提供从注塑到机器人组装的整体解决方案。
                </p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2017</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 40%; height: 7vw"
                  src="img/l18_1.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw">
                  Xu Zhou factory was established, focus on thermoset injection
                  Stepped into Electric Vehicle Market (EV) First Conformal
                  cooling tooling built.
                </p>
                <p v-show="this.$store.state.sw">
                  徐州厂落成 专注于电木产品注塑 进军电动汽车市场 。
                </p>
              </div>
            </div>
          </el-carousel-item>

          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2018</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 40%; height: 7vw"
                  src="img/l19_1.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw">Renewed IATF 16949</p>
                <p v-show="this.$store.state.sw">更新 IATF16949认证。</p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2019</h3>
              </div>
              <div class="cont">
                <p v-show="!this.$store.state.sw" style="font-size: 0.7vw">
                  Participate following Exhibitions:
                  <br />
                  K fair Dusseldorf 、 NEPCON Japan, 11th Automotive World Tokyo
                  、 IAA Cars Frankfurt 、 Automotive Aftermarket Products Expo
                  Las Vegas
                  <br />
                  Benchmarking program held by WBA Germany Purchased a set of
                  additive manufacturing equipment (metal Laser sintering
                  machine in house).
                </p>
                <p v-show="this.$store.state.sw" style="font-size: 0.7vw">
                  参加以下展览
                  <br />
                  K fair Dusseldorf 、 NEPCON Japan, 11th Automotive World Tokyo
                  、 IAA Cars Frankfurt 、 Automotive Aftermarket Products Expo
                  Las Vegas
                  <br />
                  参加德国亚琛工业大学模具学院之标干计划，提升模房智慧制能力
                  购置五金激光烧结3D打印机并自家制作随形冷流道镶件 。
                </p>
                <p v-show="!this.$store.state.sw" style="font-size: 0.7vw"></p>
                <p v-show="this.$store.state.sw" style="font-size: 0.7vw"></p>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div>
              <div class="time">
                <h3>2020</h3>
              </div>
              <div class="cont">
                <img
                  style="width: 60%; height: 7vw"
                  src="img/l20_1.png"
                  alt=""
                />

                <p v-show="!this.$store.state.sw" style="font-size: 0.7vw">
                  Support Hong Kong Public Hospitals Vender to mass production
                  eye shield as PPE during COVID19 pandemic in only 7days in
                  February, Caring Certificate under FHKI CSR Recognition
                  Scheme.
                </p>
                <p v-show="this.$store.state.sw">
                  在新冠大流行刚爆发期间，于2月份仅7天时间的批量生产防护眼罩以支持香港公立医院个人防护装备之供应,FHKICSR认可计划下的关爱证书
                  。
                </p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>

        <div class="top">
          <h3>
            <span v-show="!this.$store.state.sw"> Kenta Background </span>
            <span v-show="this.$store.state.sw">關於健大</span>
          </h3>
          <div class="xiu"></div>
          <br />
          <div class="left">
            <p v-show="!this.$store.state.sw">
              Founded in 1985 in Hong Kong, KENTA has now grown into a
              multi-national corporation specializing in the R&D, tooling
              fabrication, and production of components in precision injection
              molding.
            </p>
            <p v-show="this.$store.state.sw">
              健大企業有限公司專業從事精密塑膠注塑産品的開發和製造，一九八五年於香港成立，下設東莞工廠和昆山工廠。公司在1998年到2005年期間先後通過了UL、ISO9001、ISO14001和TS16949認證，在2007年通過大眾、寶馬的二級供應商認證。
            </p>
            <br />
          </div>
          <br />
          <div class="right">
            <p v-show="!this.$store.state.sw">
              With multiple manufacturing sites in Mainland China, Kenta has
              achieved internationally recognized standards approvals, such as
              UL, ISO9001, ISO14001and IATF16949, as well as being fully
              certified as second tier supplier for global automotive
              corporations such as VW and BMW among many Kenta's philosophy is
              to provide precise, accurate and repeatable plastic injection
              products, through taking our customers through the entire product
              lifecycle; our focus on producing only the highest quality
              outcomes, enables our global customers to have the highest
              confidence in our ability to deliver.
            </p>
            <p v-show="this.$store.state.sw">
              公司以「開拓創新、追求卓越、系統管理、客戶滿意」為經營理念，秉承「以人為本、團隊合作、社會責任、環保理念」的企業文化，精心打造創新型管理團隊和技術團隊，不斷引進高端技術和高精密設備，持續提升企業綜合實力，成長爲一家集「産品研發、模具設計與製造、精密注塑成型、汽配零部件製造」爲一體的高科技企業，業務遍及全世界。
            </p>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="buttom">
          <div class="left">
            <img src="img/c1.png" alt="" />
            <br />
            <br />
            <br />
          </div>
        </div>
        <div class="left">
          <div class="module1">
            <div class="content5">
              <div>
                <h4>
                  <span v-show="!this.$store.state.sw"> Vision </span>
                  <span v-show="this.$store.state.sw"> 使命 </span>
                  <br />
                  <div class="line"></div>
                </h4>
                <p v-show="!this.$store.state.sw">
                  To be our customers' most reliable and responsive supplier, to
                  grow our business through technology and ingenuity, and to
                  deliver leading-edge solutions with top quality while keeping
                  integrity and ethics standards.
                </p>
                <p v-show="this.$store.state.sw">
                  ● 成為客戶最可靠和反應最迅速的供應商 <br />
                  ● 通過技術和獨創性發展不同業務 <br />
                  ● 嚴緊保持誠信和道德標準同時 <br />
                  ● 提供卓越質量之全面解決方案
                </p>
              </div>
              <div>
                <h4>
                  <span v-show="!this.$store.state.sw">Mission</span>
                  <span v-show="this.$store.state.sw">願景</span>
                  <br />
                  <div class="line"></div>
                </h4>
                <p v-show="!this.$store.state.sw">
                  Be a century-long leading injection molding manufacturer that
                  provides sense of happiness and pride to all shareholders.
                </p>
                <p v-show="this.$store.state.sw">
                  ● 讓各持份者得到幸福感的百年領先模具注塑企業
                </p>
              </div>
            </div>
          </div>
          <div class="module2">
            <div class="block">
              <img
                v-for="(item, index) in 6"
                :key="item"
                :src="`img/src${index + 1}.jpg`"
              />
            </div>
          </div>
          <div class="module4">
            <h4 v-show="!this.$store.state.sw">
              Corporate Social Responsibility (CSR)
            </h4>
            <h4 v-show="this.$store.state.sw">健大的社會責任（CSR）</h4>
            <h5 v-show="!this.$store.state.sw">
              At Kenta, our commitment to corporate social responsibility (CSR),
              it is integral to our corporate culture. We recognize the
              importance of running our business to create economic value and
              social value for our employees and the communities in which we
              work, and to help preserve the planet for future generations.
              Kenta strives to expand the scope of its social engagement
              initiatives.
            </h5>
            <h5 v-show="this.$store.state.sw">
              健大企業的管理是以人為本的管理，健大的所有員工就是健大企業的顧客，企業時刻的想方設法為員工著想，在以下數方面著力：創造良好的人際關係氛圍、持續改善員工工作環境、建立良好的溝通管道、工作之餘豐富的文化活動，讓員工的滿意度得到提高。
            </h5>
            <p v-show="!this.$store.state.sw">
              Charitable donations have been made to the education sector in
              China, because we believe that every child should deserve an
              education regardless of their household living standard.More than
              20 Hope Schools have already been built since 2007.Kenta is going
              to continuously donate to the Hope Project as a means of repaying
              the community.
            </p>
            <p v-show="this.$store.state.sw">
              企業捐資助學是善舉。百年大計，教育為本，企業為教育奠基，
              為貧困山區兒童捐資助學，支持祖國教育事業的發展，幫助更多貧困學童實現求學夢想，將讓企業更有價值。健大企業於2007年開始建設希望小學，至今已建設25多間希望小學及安老院。作為社會的“企業公民”，企業利益的提升和自身價值的提升息息相關，企業的社會價值又是企業文化和價值觀的直接體現形式之一，塑造企業的優秀價值觀已經越來越重要。其中“慈善文化”已經越來越受廣大優秀企業重視並作為其文化建設的重要組成部分。在國家宣導”共建和諧社會”之際，作為社會的“企業公民”，積極參與社會慈善，關注教育事業，為貧困山區捐資助學，全面提升企業的價值觀，讓企業和社會共同進步、共同和諧發展，對自身的可持續發展具有重要意義。
            </p>
            <p v-show="!this.$store.state.sw">
              Global environmental protection is one of the main area Kenta
              concerned.To achieve the production and products are in
              environmental friendly, Kenta considers different aspects,like
              product's design, transportation, packaging and manufacturing.We
              advise our clients of the ideas, solutions and skills before
              starting the production lifecycle. In production, the reduction of
              waste and pollution, recycling and upcycling are controlled and
              supervised. Kenta strives to meet the requirement from customers,
              government, markets.
            </p>
            <p v-show="this.$store.state.sw">
              爲了積極響應全球日前環境保護觀念，健大企業在自身經營決策上，不斷通過與自然和諧的技術及對環境的友好進行產品生產，在環境的保護上力爭符合客戶、政府、市場的要求，為實現良好的人文環境和可居住的社會而努力。
            </p>
          </div>
        </div>
      </div>
      <div class="module5">
        <h4 v-show="!this.$store.state.sw" id="Sustainability">
          Sustainability
        </h4>
        <h4 v-show="this.$store.state.sw">可持續性</h4>
        <div class="content2">
          <div>
            <img src="img/earth.png" alt="" />
            <h5 v-show="!this.$store.state.sw">Environmental Friendly</h5>
            <h5 v-show="this.$store.state.sw">環保</h5>
            <span></span>
            <p v-show="!this.$store.state.sw">
              Kenta considers different aspects,like product's design,
              transportation, packaging and manufacturing.
            </p>
            <p v-show="this.$store.state.sw">
              在設計、運輸、銷售和製造方面, 有效的利用能源、水和其他資源 , 聲場
              對環境影響相對較小的產品
            </p>
          </div>
          <div>
            <img src="img/zero-emission 1.png" alt="" />
            <h5 v-show="!this.$store.state.sw">Reduce Emission</h5>
            <h5 v-show="this.$store.state.sw">減少排放物</h5>
            <span></span>
            <p v-show="!this.$store.state.sw">
              ln production, we insist on selecting tools, production processes,
              materials and production equipment that are less harmful to the
              environment.
            </p>
            <p v-show="this.$store.state.sw">
              在生產中，堅持挑選對環境危害小的工具，生產工藝流程，材料及生產設備，不斷改進工藝流程減少排放物，在不降低產品質量的前提下，確保使用對環境影響較小的原材料。
            </p>
          </div>
          <div>
            <img src="img/user 1.png" alt="" />
            <h5 v-show="!this.$store.state.sw">Staff Training</h5>
            <h5 v-show="this.$store.state.sw">員工培訓</h5>

            <span></span>
            <p v-show="!this.$store.state.sw">
              lmprove the environmental awareness of all employees of the
              company
            </p>
            <p v-show="this.$store.state.sw">
              持續通過良好的培訓體系，提升公司全體人員的環境意識，使每個員工能理解日常工作生活中
              對環境問題的思考以及所需承擔的責任
            </p>
          </div>
          <div>
            <img src="img/world 1.png" alt="" />
            <h5 v-show="!this.$store.state.sw">Reduce Waste</h5>
            <h5 v-show="this.$store.state.sw">降低廢棄物</h5>
            <span></span>
            <p v-show="!this.$store.state.sw">
              Materials will be recycled as much as possible to reduce the
              amount of materials used, so as to reduce the amount of waste
              generated and reduce pollution.
            </p>
            <p v-show="this.$store.state.sw">
              在對廢棄物的控制上，會將物料盡可能的進行循環利用，減少物料的使用量，以降低廢棄物的產生量和減少污染性。
            </p>
          </div>
          <div>
            <img src="img/shield 1.png" alt="" />
            <h5 v-show="!this.$store.state.sw">Law and Regulations</h5>
            <h5 v-show="this.$store.state.sw">符合法規</h5>
            <span></span>
            <p v-show="!this.$store.state.sw">
              Evaluate the status of the company and its compliance with
              environmental laws and regulations.
            </p>
            <p v-show="this.$store.state.sw">
              不斷的對企業現狀與環境法律法規符合性 進行評估評價，以確保企業的生
              產經營方針 與有關 法律法規符合，不斷進行改進。
            </p>
          </div>
          <div>
            <img src="img/light-bulb 1.png" alt="" />
            <h5 v-show="!this.$store.state.sw">Take Actions</h5>
            <h5 v-show="this.$store.state.sw">堅持承諾</h5>
            <h5></h5>
            <span></span>
            <p v-show="!this.$store.state.sw">
              Take effective actions to actively participate in the work of
              environmental protection.
            </p>
            <p v-show="this.$store.state.sw">
              健大企業將徹底執行上述企業環保理念，並讓全體員工知悉理念並採取有
              效行動，積極參與到環境保護的工作 中來。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">About Kenta</div>
          <div class="box" v-show="this.$store.state.sw">關於</div>
        </div>
      </div>
      <br /><br />
      <div class="top">
        <h3>
          <span v-show="!this.$store.state.sw"> Kenta Background </span>
          <span v-show="this.$store.state.sw">關於健大</span>
        </h3>
        <div class="xiu"></div>
        <br />
        <div class="left">
          <p v-show="!this.$store.state.sw">
            Founded in 1985 in Hong Kong, KENTA has now grown into a
            multi-national corporation specializing in the R&D, tooling
            fabrication, and production of components in precision injection
            molding.
          </p>
          <p v-show="this.$store.state.sw">
            健大企業有限公司專業從事精密塑膠注塑産品的開發和製造，一九八五年於香港成立，下設東莞工廠和昆山工廠。公司在1998年到2005年期間先後通過了UL、ISO9001、ISO14001和TS16949認證，在2007年通過大眾、寶馬的二級供應商認證。
          </p>
          <br />
        </div>
        <br />
        <div class="right">
          <p v-show="!this.$store.state.sw">
            With multiple manufacturing sites in Mainland China, Kenta has
            achieved internationally recognized standards approvals, such as UL,
            ISO9001, ISO14001and IATF16949, as well as being fully certified as
            second tier supplier for global automotive corporations such as VW
            and BMW among many Kenta's philosophy is to provide precise,
            accurate and repeatable plastic injection products, through taking
            our customers through the entire product lifecycle; our focus on
            producing only the highest quality outcomes, enables our global
            customers to have the highest confidence in our ability to deliver.
          </p>
          <p v-show="this.$store.state.sw">
            公司以「開拓創新、追求卓越、系統管理、客戶滿意」為經營理念，秉承「以人為本、團隊合作、社會責任、環保理念」的企業文化，精心打造創新型管理團隊和技術團隊，不斷引進高端技術和高精密設備，持續提升企業綜合實力，成長爲一家集「産品研發、模具設計與製造、精密注塑成型、汽配零部件製造」爲一體的高科技企業，業務遍及全世界。
          </p>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div class="buttom">
        <div class="left">
          <img src="img/c1.png" alt="" />
          <br />
          <br />
          <br />
        </div>
      </div>
      <div class="content5">
        <div class="module1">
          <div class="content4">
            <div>
              <h4>
                <span v-show="!this.$store.state.sw"> Vision </span>
                <span v-show="this.$store.state.sw"> 使命 </span>
                <div class="line"></div>
              </h4>
              <p v-show="!this.$store.state.sw">
                To be our customers' most reliable and responsive supplier, to
                grow our business through technology and ingenuity, and to
                deliver leading-edge solutions with top quality while keeping
                integrity and ethics standards.
              </p>
              <p v-show="this.$store.state.sw">
                ● 成為客戶最可靠和反應最迅速的供應商 <br />
                ● 通過技術和獨創性發展不同業務 <br />
                ● 嚴緊保持誠信和道德標準同時 <br />
                ● 提供卓越質量之全面解決方案
              </p>
            </div>
            <div>
              <h4>
                <span v-show="!this.$store.state.sw">Mission</span>
                <span v-show="this.$store.state.sw">願景</span>
                <div class="line"></div>
              </h4>
              <p v-show="!this.$store.state.sw">
                Be a century-long leading injection molding manufacturer that
                provides sense of happiness and pride to all shareholders.
              </p>
              <p v-show="this.$store.state.sw">
                ● 讓各持份者得到幸福感的百年領先模具注塑企業
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <div class="module2">
          <el-carousel
            height="48vw"
            style="width: 100%; margin: auto"
            indicator-position="none"
            arrow="always"
          >
            <el-carousel-item v-for="(item, index) in 6" :key="item">
              <img
                style="width: 100%"
                :src="`img/src${index + 1}.jpg`"
                alt=""
              />
            </el-carousel-item>
          </el-carousel>
        </div>
        <br /><br />

        <div class="module4">
          <h4 v-show="!this.$store.state.sw">
            Corporate Social Responsibility (CSR)
          </h4>
          <h4 v-show="this.$store.state.sw">健大的社會責任（CSR）</h4>
          <div class="tiao"></div>
          <h5 v-show="!this.$store.state.sw">
            At Kenta, our commitment to corporate social responsibility (CSR),
            it is integral to our corporate culture. We recognize the importance
            of running our business to create economic value and social value
            for our employees and the communities in which we work, and to help
            preserve the planet for future generations. Kenta strives to expand
            the scope of its social engagement initiatives.
          </h5>
          <h5 v-show="this.$store.state.sw">
            健大企業的管理是以人為本的管理，健大的所有員工就是健大企業的顧客，企業時刻的想方設法為員工著想，在以下數方面著力：創造良好的人際關係氛圍、持續改善員工工作環境、建立良好的溝通管道、工作之餘豐富的文化活動，讓員工的滿意度得到提高。
          </h5>

          <p v-show="!this.$store.state.sw">
            Charitable donations have been made to the education sector in
            China, because we believe that every child should deserve an
            education regardless of their household living standard.More than 20
            Hope Schools have already been built since 2007.Kenta is going to
            continuously donate to the Hope Project as a means of repaying the
            community.
          </p>
          <p v-show="this.$store.state.sw">
            企業捐資助學是善舉。百年大計，教育為本，企業為教育奠基，
            為貧困山區兒童捐資助學，支持祖國教育事業的發展，幫助更多貧困學童實現求學夢想，將讓企業更有價值。健大企業於2007年開始建設希望小學，至今已建設25多間希望小學及安老院。作為社會的“企業公民”，企業利益的提升和自身價值的提升息息相關，企業的社會價值又是企業文化和價值觀的直接體現形式之一，塑造企業的優秀價值觀已經越來越重要。其中“慈善文化”已經越來越受廣大優秀企業重視並作為其文化建設的重要組成部分。在國家宣導”共建和諧社會”之際，作為社會的“企業公民”，積極參與社會慈善，關注教育事業，為貧困山區捐資助學，全面提升企業的價值觀，讓企業和社會共同進步、共同和諧發展，對自身的可持續發展具有重要意義。
          </p>
          <p v-show="!this.$store.state.sw">
            Global environmental protection is one of the main area Kenta
            concerned.To achieve the production and products are in
            environmental friendly, Kenta considers different aspects,like
            product's design, transportation, packaging and manufacturing.We
            advise our clients of the ideas, solutions and skills before
            starting the production lifecycle. In production, the reduction of
            waste and pollution, recycling and upcycling are controlled and
            supervised. Kenta strives to meet the requirement from customers,
            government, markets.
          </p>
          <p v-show="this.$store.state.sw">
            爲了積極響應全球日前環境保護觀念，健大企業在自身經營決策上，不斷通過與自然和諧的技術及對環境的友好進行產品生產，在環境的保護上力爭符合客戶、政府、市場的要求，為實現良好的人文環境和可居住的社會而努力。
          </p>
          <h3 v-show="!this.$store.state.sw">Milestone</h3>
          <h3 v-show="this.$store.state.sw">里程碑</h3>
          <div class="tiao"></div>
        </div>

        <div class="right">
          <div>
            <div class="time">
              <h3>1985</h3>
            </div>
            <div class="cont">
              <img style="width: 30%" src="img/l1.png" alt="" />
              <p v-show="!this.$store.state.sw">
                Kenta Enterprise Ltd Co. was founded in Hong Kong.
              </p>
              <p v-show="this.$store.state.sw">
                健大企業有限公司于於香港成立。
              </p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img style="width: 30%; height: 11vw" src="img/zbj4.png" alt="" />
              <img style="width: 30%; height: 11vw" src="img/l2.png" alt="" />
              <p v-show="!this.$store.state.sw">
                The first factory was established in Dong Guan, Guang Dong,
                targeting at the market in traditional transformers.
              </p>
              <p v-show="this.$store.state.sw">
                新工廠於廣東東莞建立,專注於傳統變壓器市場。
              </p>
            </div>
            <div class="time">
              <h3>1991</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>1998</h3>
            </div>
            <div class="cont">
              <img style="width: 15%; height: 12vw" src="img/l3.png" alt="" />
              <img style="width: 15%; height: 12vw" src="img/l3_2.png" alt="" />
              <img style="width: 30%; height: 12vw" src="img/l3_3.png" alt="" />
              <p v-show="!this.$store.state.sw">
                Dong Guan factory was awarded ISO 9001 certificate & UL
                accreditation. The factory developed a new market in SMD
                transformers.
              </p>
              <p v-show="this.$store.state.sw">
                東莞健大通過ISO 9001 & UL 認證。開發SMD類變壓器。
              </p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img style="width: 40%; height: 13vw" src="img/l4.png" alt="" />
              <img style="width: 40%; height: 13vw" src="img/l4_2.png" alt="" />
              <p v-show="!this.$store.state.sw">
                A new factory complex was established in Dong Guan. The factory
                developed a new market in automotive products and began to
                develop and manufacture igniters for Ford.
              </p>
              <p v-show="this.$store.state.sw">
                東莞建立花園式新廠區。開發汽車零件類產品。
              </p>
            </div>
            <div class="time">
              <h3>2000</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>2001</h3>
            </div>
            <div class="cont">
              <img style="width: 40%; height: 13vw" src="img/l5_1.png" alt="" />
              <img style="width: 40%; height: 13vw" src="img/l5_2.png" alt="" />
              <p v-show="!this.$store.state.sw">
                The company stepped into the market in network and connector
                products.
              </p>
              <p v-show="this.$store.state.sw">開發網絡連接器類產品。</p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img style="width: 16%; height: 13vw" src="img/l3_2.png" alt="" />
              <img style="width: 16%; height: 13vw" src="img/l6_2.png" alt="" />
              <img style="width: 30%; height: 13vw" src="img/t1.png" alt="" />
              <p v-show="!this.$store.state.sw">
                The company stepped into the market in network and connector
                products. The company started to build a new and larger factory
                complex in Kun Shan, Jiangsu.
              </p>
              <p v-show="this.$store.state.sw">
                通過SONY GP and IOS 14000認證。
                開始於江蘇昆山建立更大的花圜式新工廠
              </p>
            </div>
            <div class="time">
              <h3>2003</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>2005</h3>
            </div>
            <div class="cont">
              <img style="width: 30%; height: 13vw" src="img/l8.png" alt="" />
              <img style="width: 16%; height: 13vw" src="img/l8_1.png" alt="" />
              <p v-show="!this.$store.state.sw">
                Kun Shan Factory was estab-lished to focus on developing and
                manufacturing automotive products.Dong Guan Factory obtained ISO
                TS16949 certification.
              </p>
              <p v-show="this.$store.state.sw">
                昆山工廠成立專注於研發和生産汽事產品。東莞工廠通過 IOS TS16949
                認證。
              </p>

              <p v-show="!this.$store.state.sw"></p>
              <p v-show="this.$store.state.sw"></p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img style="width: 16%; height: 13vw" src="img/l7.png" alt="" />
              <img style="width: 30%; height: 13vw" src="img/l7_1.png" alt="" />
              <img style="width: 30%; height: 13vw" src="img/l7_2.png" alt="" />
              <p v-show="!this.$store.state.sw">
                Kun Shan Factory obtained UL & ISO certifications. The factory
                developed a new market in network communications products.
              </p>
              <p v-show="this.$store.state.sw">
                昆山工廠通過UL&ISO認證。開發網絡通訊類產品，
              </p>
            </div>
            <div class="time">
              <h3>2006</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>2008</h3>
            </div>
            <div class="cont">
              <img style="width: 16%; height: 13vw" src="img/l9_1.png" alt="" />
              <img style="width: 16%; height: 13vw" src="img/l9_2.png" alt="" />
              <img style="width: 27%; height: 13vw" src="img/l9_3.png" alt="" />
              <img style="width: 27%; height: 13vw" src="img/l9_4.png" alt="" />
              <p v-show="!this.$store.state.sw">
                Kun Shan Factory was awarded ISO 14000 & TS 16949
                certifica-tions. Dong Guan Factory was qualified as a second
                tier supplier for Volkswagen and BMW.
              </p>
              <p v-show="this.$store.state.sw">
                昆山工廠通過ISO 14000 & TS16949。
                廣東東莞工廠獲得大眾(VM)和寶馬(BMW)的二級供應商資格。
              </p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img
                style="width: 30%; height: 13vw"
                src="img/l10_1.png"
                alt=""
              />
              <img
                style="width: 45%; height: 13vw"
                src="img/l10_2.png"
                alt=""
              />
              <p v-show="!this.$store.state.sw">
                Dong Guan Factory began to develop and manufacture electronic
                control systems and molds for Volkswagen.
              </p>
              <p v-show="this.$store.state.sw">
                東莞工廠參與研發及生產大眾汽車(VM)之電子控制系統之產品和模具
              </p>
            </div>
            <div class="time">
              <h3>2009</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>2011</h3>
            </div>
            <div class="cont">
              <img style="width: 35%" src="img/l11.png" alt="" />
              <p v-show="!this.$store.state.sw">
                The company started to develope low-voltage electrical products,
                and BMC products and molds.
              </p>
              <p v-show="this.$store.state.sw">
                開發了低壓電器類產品並研發和生產BMC(團狀模塑料)類產品和模具
              </p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img
                style="width: 20%; height: 10vw"
                src="img/l14_1.png"
                alt=""
              />
              <img
                style="width: 20%; height: 10vw"
                src="img/l14_2.png"
                alt=""
              />
              <img
                style="width: 20%; height: 10vw"
                src="img/l14_3.png"
                alt=""
              />
              <img
                style="width: 25%; height: 10vw"
                src="img/l14_5.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw">
                A professional automation R&D team was established. The team
                began to develop and manufacture automo-tive ignition switch
                molds, one-port molds, remote key molds, and etc. for
                SAIC-GM-Wuling Automobile.
              </p>
              <p v-show="this.$store.state.sw">
                建立起專業的自動化研發團隊。
                開始為上汽通用五菱配套研發並生產汽車點火開關模組、一鍵啟動模組、遙控鑰匙模組。
              </p>
            </div>
            <div class="time">
              <h3>2013</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>2014</h3>
            </div>
            <div class="cont">
              <img
                style="width: 40%; height: 16vw"
                src="img/l15_1.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw">
                Establish in House Stamping workshop in Kun Shan.
              </p>
              <p v-show="this.$store.state.sw">昆山厂成立五金冲压车间。</p>
            </div>
          </div>
          <div>
            <div class="cont">
              <img
                style="width: 40%; height: 16vw"
                src="img/l16_1.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw">
                Stepped into medical Device Market.
              </p>
              <p v-show="this.$store.state.sw">
                东莞厂成立 专注于传统骨架市场。
              </p>
            </div>
            <div class="time">
              <h3>2015</h3>
            </div>
          </div>

          <div>
            <div class="time">
              <h3>2016</h3>
            </div>
            <div class="cont">
              <img
                style="width: 40%; height: 16vw"
                src="img/l17_1.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw">
                The first fully automated production line MP. Total solutions
                from injection molding to robotic assembl
              </p>
              <p v-show="this.$store.state.sw">
                首条全自动生产线投入量产 提供从注塑到机器人组装的整体解决方案。
              </p>
            </div>
          </div>

          <div>
            <div class="cont">
              <img
                style="width: 40%; height: 16vw"
                src="img/l18_1.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw">
                Xu Zhou factory was established, focus on thermoset injection
                Stepped into Electric Vehicle Market (EV) First Conformal
                cooling tooling built.
              </p>
              <p v-show="this.$store.state.sw">
                徐州厂落成 专注于电木产品注塑 进军电动汽车市场 。
              </p>
            </div>
            <div class="time">
              <h3>2017</h3>
            </div>
          </div>

          <div>
            <div class="time">
              <h3>2018</h3>
            </div>
            <div class="cont">
              <img
                style="width: 40%; height: 16vw"
                src="img/l19_1.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw">Renewed IATF 16949</p>
              <p v-show="this.$store.state.sw">更新 IATF16949认证。</p>
            </div>
          </div>

          <div>
            <div class="cont">
              <p v-show="!this.$store.state.sw" style="font-size: 0.7vw">
                Participate following Exhibitions:
                <br />
                K fair Dusseldorf 、 NEPCON Japan, 11th Automotive World Tokyo
                、 IAA Cars Frankfurt 、 Automotive Aftermarket Products Expo
                Las Vegas
                <br />
                Benchmarking program held by WBA Germany Purchased a set of
                additive manufacturing equipment (metal Laser sintering machine
                in house).
              </p>
              <p v-show="this.$store.state.sw" style="font-size: 0.7vw">
                参加以下展览
                <br />
                K fair Dusseldorf 、 NEPCON Japan, 11th Automotive World Tokyo
                、 IAA Cars Frankfurt 、 Automotive Aftermarket Products Expo
                Las Vegas
                <br />
                参加德国亚琛工业大学模具学院之标干计划，提升模房智慧制能力
                购置五金激光烧结3D打印机并自家制作随形冷流道镶件 。
              </p>
              <p v-show="!this.$store.state.sw" style="font-size: 0.7vw"></p>
              <p v-show="this.$store.state.sw" style="font-size: 0.7vw"></p>
            </div>
            <div class="time">
              <h3>2019</h3>
            </div>
          </div>
          <div>
            <div class="time">
              <h3>2020</h3>
            </div>
            <div class="cont">
              <img
                style="width: 50%; height: 16vw"
                src="img/l20_1.png"
                alt=""
              />

              <p v-show="!this.$store.state.sw" style="font-size: 0.7vw">
                Support Hong Kong Public Hospitals Vender to mass production eye
                shield as PPE during COVID19 pandemic in only 7days in February,
                Caring Certificate under FHKI CSR Recognition Scheme.
              </p>
              <p v-show="this.$store.state.sw">
                在新冠大流行刚爆发期间，于2月份仅7天时间的批量生产防护眼罩以支持香港公立医院个人防护装备之供应,FHKICSR认可计划下的关爱证书
                。
              </p>
            </div>
          </div>
        </div>
        <div class="module5">
          <h4 v-show="!this.$store.state.sw">Sustainability</h4>
          <h4 v-show="this.$store.state.sw">可持續性</h4>
          <div class="content2">
            <div>
              <img src="img/earth.png" alt="" />
              <h5 v-show="!this.$store.state.sw">Environmental Friendly</h5>
              <h5 v-show="this.$store.state.sw">環保</h5>
              <span></span>
              <p v-show="!this.$store.state.sw">
                Kenta considers different <br />
                aspects,like product's design, <br />
                transportation, packaging and <br />
                manufacturing.
              </p>
              <p v-show="this.$store.state.sw">
                在設計、運輸、銷售和製造方面, 有 <br />
                效的利用能源、 水和其他資源 , 聲場 <br />
                對環境影響相對較小的產品
              </p>
            </div>
            <div>
              <img src="img/zero-emission 1.png" alt="" />
              <h5 v-show="!this.$store.state.sw">Reduce Emission</h5>
              <h5 v-show="this.$store.state.sw">減少排放物</h5>
              <span></span>
              <p v-show="!this.$store.state.sw">
                ln production, we insist on <br />
                selecting tools, production <br />
                processes, materials and <br />
                production equipment that are <br />
                less harmful to the environment.
              </p>
              <p v-show="this.$store.state.sw">
                在生產中，堅持挑選對環境危害小的 <br />
                工具， 生產工藝流程，材料及生產設 <br />
                備， 不斷改進工藝流程減少排放物， <br />
                在不降低產品質量的前提下， 確保使 <br />
                用對環境影響較小的原材料。
              </p>
            </div>
            <div>
              <img src="img/user 1.png" alt="" />
              <h5 v-show="!this.$store.state.sw">Staff Training</h5>
              <h5 v-show="this.$store.state.sw">員工培訓</h5>

              <span></span>
              <p v-show="!this.$store.state.sw">
                lmprove the environmental <br />
                awareness of all employees of <br />
                the company
              </p>
              <p v-show="this.$store.state.sw">
                持續通過良好的培訓體系，提升公司 <br />
                全體人員的環境意識， 使每個員工能 <br />
                理解日常工作生活中 對環境問題的思 <br />
                考以及所需承擔的責任
              </p>
            </div>
            <div>
              <img src="img/world 1.png" alt="" />
              <h5 v-show="!this.$store.state.sw">Reduce Waste</h5>
              <h5 v-show="this.$store.state.sw">降低廢棄物</h5>
              <span></span>
              <p v-show="!this.$store.state.sw">
                Materials will be recycled as much as possible to reduce the
                amount of materials used, so as to reduce the amount of waste
                generated and reduce pollution.
              </p>
              <p v-show="this.$store.state.sw">
                在對廢棄物的控制上，會將物料盡可 <br />
                能的進行循環利用，減少物料的使用 <br />
                量，以降低廢棄物的產生量和減少污 <br />
                染性。
              </p>
            </div>
            <div>
              <img src="img/shield 1.png" alt="" />
              <h5 v-show="!this.$store.state.sw">Law and Regulations</h5>
              <h5 v-show="this.$store.state.sw">符合法規</h5>
              <span></span>
              <p v-show="!this.$store.state.sw">
                Evaluate the status of the <br />
                company and its compliance with <br />
                environmental laws and <br />
                regulations
              </p>
              <p v-show="this.$store.state.sw">
                不斷的對企業現狀與環境法律法規符 <br />
                合性 進行評估評價，以確保企業的生 <br />
                產經營方針 與有關 法律法規符合，不 <br />
                斷進行改進。
              </p>
            </div>
            <div>
              <img src="img/light-bulb 1.png" alt="" />
              <h5 v-show="!this.$store.state.sw">Take Actions</h5>
              <h5 v-show="this.$store.state.sw">堅持承諾</h5>
              <h5></h5>
              <span></span>
              <p v-show="!this.$store.state.sw">
                Take effective actions to actively <br />
                participate in the work of <br />
                environmental protection.
              </p>
              <p v-show="this.$store.state.sw">
                健大企業將徹底執行上述企業環保理 <br />
                念，並讓全體員工知悉理念並採取有 <br />
                效行動，積極參與到環境保護的工作 <br />
                中來。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [
        {
          ming: "img/l1.png",
          时间: "1985",
          content: "Kenta Enterprise Ltd Co. was founded in Hong Kong.",
          zn: "健大企業有限公司于於香港成立",
        },
        {
          ming: "img/l2.png",
          时间: "1991",
          content:
            "The first factory was established in Dong Guan, Guang Dong,targeting at the market in traditional transformers.",
          zn: "新工廠於廣東東莞建立專注於傳統變壓器市場",
        },
        {
          ming: "img/l3.png",
          时间: "1998",
          content:
            "Dong Guan factory was awarded ISO 9001 certificate & UL accreditation.The factory developed a new market in SMD transformers.",
          zn: "柬莞健大通遏lso 9001& UL認證,開發SMD類變壓器.",
        },
        {
          ming: "img/l4.png",
          时间: "2000",
          content:
            "A new factory complex was established in Dong Guan.The factory developed a new market in automotive products and began to develop and manufacture igniters for Ford.",
          zn: "東莞建立花園式新廠區,開發汽車零件類產品",
        },
        {
          ming: "img/l5.png",
          时间: "2001",
          content:
            "The company stepped into the market in network and connector products.",
          zn: "開髮網絡連接器產品",
        },
        {
          ming: "img/l6.png",
          时间: "2003",
          content:
            "The company was awarded Sony GP and ISO 14000 certificates. The company started to build a new and larger factory complex in Kun Shan, Jiangsu.",
          zn: "通過SONY GP and lSO 14000認證,開始於江蘇昆山建立更大的花圜式新工廠",
        },

        {
          ming: "img/l7.png",
          时间: "2005",
          content:
            "Kun Shan Factory was established to focus on developing and manufacturing automotive products.",
          zn: "昆山工廠成立專注於研發和生産汽事產品,束莞工瘢通遹ISO TS16949認證",
        },
        {
          ming: "img/l8.png",
          时间: "2006",
          content:
            "Kun Shan Factory obtained UL & ISO certifications.The factory developed a new market in network communications products.",
          zn: "昆山工廠通過UL&ISO認證,開發網絡通訊類產品",
        },
        {
          ming: "img/l9.png",
          时间: "2009",
          content:
            "Dong Guan Factory began to develop and manufacture  electronic control systems  and molds for Volkswagen.",
          zn: "東莞工敞参舆研及生崖大宗汽車（VW）之電子控制系统之隆品和模具",
        },
        {
          ming: "img/l10.png",
          时间: "2011",
          content:
            "The company started to develope low-voltage electrical products, and BMC products and molds.",
          zn: "開毅了低麽電器頫隆品立研毅和生隆BMC（围状模塑料）頫隆品和模具",
        },
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  mounted() {
    var hash = window.location.hash;
    var index = hash.lastIndexOf("#");
    if (index != -1) {
      var id = hash.substring(index + 1, hash.length + 1);
      var div = document.getElementById(id);
      if (div) {
        //$('html,body').scrollTop($(div).offset().top - 43);
        $("html, body").animate({ scrollTop: $(div).offset().top - 43 }, 500);
      }
    } else {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    arrowClick(val) {
      this.$refs.cardShow.prev();
    },
    arrowClick2(val) {
      this.$refs.cardShow.next();
    },
  },
};
</script>

<style lang="less" scoped>
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    .mask {
      z-index: 99999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;

        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
      }
    }
  }
  .line {
    width: 3vw;
    height: 0.2vw;
    background: red;
    margin: 1vw 0;
  }
  h3 {
    box-sizing: border-box;
    font-size: 2vw;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.05em;
  }
  .jar {
    width: 60%;
    margin: 0 auto;
    padding-top: 3vw;
    // display: flex;
    .xiu {
      margin: 1vw 0;
      background: red;
      height: 0.2vw;
      width: 5vw;
    }
    > .left {
      width: 100%;
      vertical-align: top;
    }
    .top {
      margin-top: 5vw;
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 1.8vw;
        letter-spacing: 0.05em;
      }

      p {
        line-height: 150%;
        text-align: justify;
      }
      .left {
        font-size: 1.3vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
      .right {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.05em;
        font-size: 1vw;
        color: gray;
      }
    }
    .buttom {
      display: flex;
      justify-content: space-between;
      > div {
        overflow: hidden;
      }
      .left {
        width: 100%;
        position: relative;
        div {
          position: absolute;
          width: 100%;
          height: 100%;
          background: rgba(128, 128, 128, 0.144);
          top: 0;
        }
        img {
          width: 100%;
        }
      }
    }
    .el-carousel__item > div {
      background: white;
      margin: 0;
      height: 13vw;
      overflow: hidden;
      display: flex;
      .time {
        width: 20%;
        font-size: 1.2vw;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        // background: rgb(253, 56, 46);
        background: rgba(224, 83, 76, 1);
      }
      .cont {
        padding: 1vw;
        width: 80%;
        p {
          text-align: left;
          font-size: 1vw;
          margin: 0 auto;
          padding-top: 0.5vw;
        }
      }
      border-radius: 1vw;
      border-bottom-left-radius: 1vw;
    }
    // .el-carousel__mask {
    //   height: 11vw !important;
    // }

    // > .right {
    //   .cont {
    //     padding: 1vw;
    //   }
    //   vertical-align: top;
    //   width: 100%;
    //   padding: 1vw 0;
    //   text-align: center;
    //   > :nth-child(2n + 1) {
    //     .time {
    //       width: 20%;
    //       font-size: 1.2vw;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       color: white;
    //       border-top-left-radius: 1vw;
    //       border-bottom-left-radius: 1vw;
    //     }
    //     .cont {
    //       width: 80%;
    //       img {
    //         float: left;
    //         margin: 0.2vw;
    //       }
    //     }
    //     width: 80%;
    //     height: 11vw;
    //     border-radius: 1vw;
    // background: white;
    //     margin: 1vw auto;
    //     display: flex;

    //     p {
    //       text-align: left;
    //       padding: 0.5vw;
    //       font-size: 1vw;
    //       margin: 0 auto;
    //       width: 90%;
    //     }
    //   }
    //   > :nth-child(2n) {
    //     .time {
    //       width: 20%;
    //       font-size: 1.2vw;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       color: white;
    //       border-top-right-radius: 1vw;
    //       border-bottom-right-radius: 1vw;
    //     }
    //     .cont {
    //       width: 80%;
    //       img {
    //         float: right;
    //         margin: 0.2vw;
    //       }
    //     }

    //     > div {
    //       width: 100%;
    //     }
    //     width: 80%;
    //     height: 11vw;
    //     border-radius: 1vw;
    //     margin: 1vw auto;
    //     display: flex;
    //     p {
    //       width: 90%;
    //       text-align: left;
    //       padding: 0.5vw;
    //       font-size: 1.1vw;
    //       margin: 0 auto;
    //     }
    //   }
    //   > :nth-child(1) {
    //     .time {
    //       background: red;
    //     }
    //   }
    //   > :nth-child(2) {
    //     .time {
    //       background: orange;
    //     }
    //   }
    //   > :nth-child(3) {
    //     .time {
    //       background: rgb(221, 221, 4);
    //     }
    //   }
    //   > :nth-child(4) {
    //     .time {
    //       background: green;
    //     }
    //   }
    //   > :nth-child(5) {
    //     .time {
    //       background: rgb(24, 248, 248);
    //     }
    //   }
    //   > :nth-child(6) {
    //     .time {
    //       background: rgb(8, 8, 218);
    //     }
    //   }
    //   > :nth-child(7) {
    //     .time {
    //       background: purple;
    //     }
    //   }
    //   > :nth-child(8) {
    //     .time {
    //       background: indigo;
    //     }
    //   }
    //   > :nth-child(9) {
    //     .time {
    //       background: violet;
    //     }
    //   }
    //   > :nth-child(10) {
    //     .time {
    //       background: rgb(96, 56, 17);
    //     }
    //   }
    //   > :nth-child(11) {
    //     .time {
    //       background: orangered;
    //     }
    //   }
    //   > :nth-child(12) {
    //     .time {
    //       background: rgb(170, 151, 145);
    //     }
    //   }
    // }
  }
  .module1 {
    margin: 0 auto;
    .content5 {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      > div {
        width: 46%;
        vertical-align: top;
        h4 {
          font-size: 1.6vw;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.05em;
        }
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          color: grey;
          font-size: 1vw;
          text-align: justify;
        }
      }
    }
  }
  .module2 {
    width: 100%;
    text-align: center;
    margin: 3vw 0;
    .block {
      display: flex;
      justify-content: space-between;
    }
    img {
      width: 9vw;
      height: 6vw;
    }
  }
  .module4 {
    margin: auto;
    h4 {
      font-size: 2vw;
      margin: 2vw 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.05em;
    }
    h5 {
      width: 100%;
      font-size: 1vw;
      letter-spacing: 0.05em;
      text-align: justify;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
    }
    p {
      color: gray;
      margin: 2vw 0;
      font-family: Roboto;
      font-style: normal;
      text-align: justify;
      font-weight: normal;
      letter-spacing: 0.05em;
      font-size: 1vw;
    }
  }
  .module5 {
    background: white;
    > h4 {
      width: 80%;
      margin: auto;
      font-size: 2vw;
      box-sizing: border-box;
      padding: 4vw 0vw 1vw 6vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.05em;
    }
    .content2 {
      padding: 2vw;
      width: 80%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > div {
        width: 30%;
        text-align: center;
        margin-top: 2vw;
        h5 {
          font-size: 1.2vw;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          letter-spacing: 0.05em;
          margin: 1vw 0;
        }
        img {
          width: 5vw;
          margin: 1vw 0;
        }
        p {
          width: 80%;
          margin: auto;
          color: gray;
          font-size: 0.9vw;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          letter-spacing: 0em;
        }
      }
    }
  }
}
.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .top {
    width: 80%;
    margin: auto;
    margin-top: 5vw;
    h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 5vw;
      letter-spacing: 0.05em;
    }

    p {
      text-align: justify;
      font-size: 3.5vw;
    }
    .left {
      font-size: 1.3vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.05em;
    }
    .right {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.05em;
      font-size: 1vw;
      color: gray;
    }
  }
  .buttom {
    display: flex;
    justify-content: space-between;
    > div {
      overflow: hidden;
    }
    .left {
      width: 100%;
      position: relative;
      div {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(128, 128, 128, 0.144);
        top: 0;
      }
      img {
        width: 100%;
      }
    }
  }
  .content5 {
    .module1 {
      width: 90%;
      margin: 0 auto;
      .title {
        font-size: 8vw;
        margin: 15vw 0 10vw 0;
      }
      .content4 {
        > div {
          margin: 10vw 0;
          .line {
            background: red;
            width: 14vw;
            height: 0.5vw;
            margin: 5vw 0;
          }
          h4 {
            font-size: 5vw;
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
          }
          p {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 3.5vw;
            color: #686b71;
          }
        }
      }
    }
    .module2 {
      width: 100%;
      overflow: hidden;
      img {
        width: 130%;
      }
    }
    .module3 {
      > h3 {
        font-size: 8vw;
        text-align: center;
      }
      > .left {
        margin-top: 40%;
        text-align: center;
        transform: rotateZ(-90deg);
        width: 10%;
        float: left;
        img {
          width: 10vw;
        }
      }
      > .right {
        margin-top: 40%;
        width: 10%;
        transform: rotateZ(90deg);
        float: right;
        img {
          width: 10vw;
        }
      }
      .block {
        width: 60%;
        box-sizing: border-box;
        padding: 3%;
        height: 70vw;
        margin: 10vw auto;
        background: gray;
        color: white;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
      }
      .bo {
        .top {
          text-align: center;
          height: 45vw;
          position: relative;
          p {
            text-align: left;
            font-size: 5vw;
            margin: 2vw 0;
            position: absolute;
            bottom: 0;
          }
          img {
            width: 70%;
          }
        }
        .bottom {
          height: 20vw;
          padding: 4% 0;
          overflow: hidden;
          > div {
            height: 20vw;
          }
        }
      }
    }
    .module4 {
      width: 80%;
      margin: 0 auto;

      h3 {
        font-size: 5vw;
        margin-top: 5vw;
      }
      h4 {
        font-size: 6vw;
        width: 80%;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
      }
      h5 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        margin-top: 5vw;
        font-size: 4.5vw;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        text-align: justify;
        font-weight: normal;
        color: #636264;
        font-size: 3.5vw;
      }
      .tiao {
        background: red;
        width: 20vw;
        height: 0.5vw;
        margin: 3vw 0;
      }
    }
    > .right {
      .cont {
        padding: 1vw;
      }
      vertical-align: top;
      width: 100%;
      padding: 1vw 0;
      text-align: center;

      > :nth-child(2n + 1) {
        .time {
          width: 20%;
          font-size: 3vw;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border-top-left-radius: 1vw;
          border-bottom-left-radius: 1vw;
        }
        .cont {
          width: 80%;
          img {
            margin: 0.2vw;
          }
        }
        width: 80%;
        height: 30vw;
        border-radius: 1vw;
        background: white;
        margin: 1vw auto;
        display: flex;

        p {
          text-align: left;
          padding: 0.5vw;
          font-size: 2.5vw;
          margin: 0 auto;
          width: 90%;
        }
      }
      > :nth-child(2n) {
        .time {
          width: 20%;
          font-size: 3vw;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border-top-right-radius: 1vw;
          border-bottom-right-radius: 1vw;
        }
        .cont {
          width: 80%;
          img {
            margin: 0.2vw;
          }
        }

        > div {
          width: 100%;
        }
        width: 80%;
        height: 30vw;
        border-radius: 1vw;
        background: white;
        margin: 1vw auto;
        display: flex;
        p {
          width: 90%;
          text-align: left;
          padding: 0.5vw;
          font-size: 2.5vw;
          margin: 0 auto;
        }
      }
      > :nth-child(2n + 1) {
        .time {
          background: rgba(224, 83, 76, 1);
        }
      }
      > :nth-child(2n) {
        .time {
          background: silver;
        }
      }
    }
    .module5 {
      width: 80%;
      margin: 15vw auto;
      text-align: center;
      .content2 > div {
        margin: 30vw 0;
      }
      h5 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 5vw;
      }
      img {
        width: 20%;
      }
      h4 {
        font-size: 8vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
      }
      p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 4vw;
        color: #686b71;
      }
    }
  }
}
</style>
