<template>
  <div class="total">
    <div class="pc">
      <div class="banner">
        <el-carousel trigger="click" height="34vw" :interval="5000">
          <el-carousel-item>
            <img class="img1" src="img/zbj.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img2" src="img/zbj2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img3" src="img/zbj3.png" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Total Solutions</div>
          <div class="box" v-show="this.$store.state.sw">解決方案</div>
        </div>
      </div>
      <div class="content">
        <div>
          <div class="right" v-show="!this.$store.state.sw">
            We utilize EU RoHS, MFl tests and moisture tests to ensure all
            plastic materials are maintained at the highest quality
            requirements. For metallic materials, along with X-ray layer
            testing, we have added a full automatic CCD test facility to ensure
            the mold and metallic materials will be fully compatible.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            原材料皆有進行歐盟RoHS，MFI測試和水分測試，以確保所有塑膠材料都將保持高品質要求。對於金屬材料，除了進行X射線層測試，我們還添加了全自動CCD測試，以確保模具和金屬材料具有高的相容性。
          </div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">Selection of Materials</h3>
            <h3 v-show="this.$store.state.sw">原材料採用建議</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 115%" src="img/s1.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img style="width: 115%" src="img/s1.2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">Risk Assessment</h3>
            <h3 v-show="this.$store.state.sw">全制程風險評估及對策</h3>
            <div class="img">
              <div style="width: 65%">
                <img style="width: 100%" src="img/s2.2.png" alt="" />
              </div>
              <div style="width: 35%">
                <img style="width: 110%" src="img/s2.1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            With more than 28 years of experience,via the KENTA product
            portfolio database, our engineers can provide a complete risk
            assessment to our customers.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            憑藉超過三十餘年的經驗，通過KENTA產品組合數據庫，我們的工程師可以為客戶提供完整的風險評估。
          </div>
        </div>
        <div>
          <div class="right" v-show="!this.$store.state.sw">
            ln our goal to build long and robust relationships, we have a
            lifecycle approach; We develop processes for new products, we
            provide professional advice on raw materials, the molding process
            and mold design used, we also provide professional advice for
            product improvement, to ensure the high-quality products are
            produced.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            我們追求長期合作夥伴關係，在新產品開發過程中，對於所採用的原材料，成型工藝和模具設計，健大都會提供專業的意見，為產品改進提供專業建議，確保能生產高質量的產品。
          </div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">ldeas for Product Design</h3>
            <h3 v-show="this.$store.state.sw">產品設計改善建議</h3>
            <div class="img">
              <div style="width: 55%">
                <img style="width: 110%" src="img/s3.1.png" alt="" />
              </div>
              <div style="width: 45%">
                <img style="width: 110%" src="img/s3.2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">Sampling</h3>
            <h3 v-show="this.$store.state.sw">製作樣品</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 100%" src="img/s4.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img src="img/s4.2.png" style="width: 100%" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            By ongoing testing and quality assurance, carrying out EU RoHS
            tests, MFI test and moisture tests, we can ensure out plastic
            material will be maintained at the highest of quality for production
          </div>
          <div class="right" v-show="this.$store.state.sw">
            我們會對樣品進行歐盟RoHS測試，MFI測試和水分測試，這樣可以確保所有的產品將保持高品質的生產。
          </div>
        </div>
        <div>
          <div class="right" v-show="!this.$store.state.sw">
            We understand that our customers demand the highest standards for
            automatic inspection, we utilize special CCD mirror inspections to
            ensure that all products meet high-quality standards.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            我們瞭解客戶所想，對自動檢測，特殊CCD視鏡檢測的檢測要求很高，以確保所有產品符合高質量標準。
          </div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">lnspection</h3>
            <h3 v-show="this.$store.state.sw">檢測</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 115%" src="img/s5.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img style="width: 119%" src="img/s5.2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">
              Automated Production and <br />
              Quality Assurance
            </h3>
            <h3 v-show="this.$store.state.sw">自動化生產及檢驗</h3>
            <div class="img">
              <div style="width: 60%">
                <img src="img/s6.1.png" style="width: 100%" alt="" />
              </div>
              <div style="width: 40%">
                <img src="img/s6.2.png" style="width: 150%" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            Across the lifecycle, from the selection of raw materials, to the
            automation of inspections, Kenta and our customers can be sure of
            accurate production plans and maintain the highest quality of
            products.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            從原材料選擇到後期檢驗的自動化，讓健大和我們的客戶獲得精確的生產計劃，保持高質量產品。
          </div>
        </div>
        <div>
          <div class="right" v-show="!this.$store.state.sw">
            Our relationship and service do not end at shipping; our engineers
            maintain communication with our customers and continue to make
            suggestions for improvement, to provide ongoing efficiency uplift.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            產品完成出貨程式後不是意味著與合作夥伴關係的結束。我們的工程師會向客戶保持溝通，有需要時會提出改進建議，形成雙贏局面。
          </div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">
              Proactive After-sales Service
            </h3>
            <h3 v-show="this.$store.state.sw">高質量的售後服務</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 120%" src="img/s7.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img style="width: 120%" src="img/s7.2.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Solutions</div>
          <div class="box" v-show="this.$store.state.sw">解決方案</div>
        </div>
      </div>
      <div class="content5">
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">Selection of Materials</h3>
            <h3 v-show="this.$store.state.sw">原材料採用建議</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 115%" src="img/s1.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img style="width: 115%" src="img/s1.2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            We utilize EU RoHS, MFl tests and moisture tests to ensure all
            plastic materials are maintained at the highest quality
            requirements. For metallic materials, along with X-ray layer
            testing, we have added a full automatic CCD test facility to ensure
            the mold and metallic materials will be fully compatible.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            原材料皆有進行歐盟RoHS，MFI測試和水分測試，以確保所有塑膠材料都將保持高品質要求。對於金屬材料，除了進行X射線層測試，我們還添加了全自動CCD測試，以確保模具和金屬材料具有高的相容性。
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">Risk Assessment</h3>
            <h3 v-show="this.$store.state.sw">全制程風險評估及對策</h3>
            <div class="img">
              <div style="width: 65%">
                <img style="width: 100%" src="img/s2.2.png" alt="" />
              </div>
              <div style="width: 35%">
                <img style="width: 110%" src="img/s2.1.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            With more than 28 years of experience,via the KENTA product
            portfolio database, our engineers can provide a complete risk
            assessment to our customers.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            憑藉超過三十餘年的經驗，通過KENTA產品組合數據庫，我們的工程師可以為客戶提供完整的風險評估。
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">ldeas for Product Design</h3>
            <h3 v-show="this.$store.state.sw">產品設計改善建議</h3>
            <div class="img">
              <div style="width: 55%">
                <img style="width: 110%" src="img/s3.1.png" alt="" />
              </div>
              <div style="width: 45%">
                <img style="width: 110%" src="img/s3.2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            ln our goal to build long and robust relationships, we have a
            lifecycle approach; We develop processes for new products, we
            provide professional advice on raw materials, the molding process
            and mold design used, we also provide professional advice for
            product improvement, to ensure the high-quality products are
            produced.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            我們追求長期合作夥伴關係，在新產品開發過程中，對於所採用的原材料，成型工藝和模具設計，健大都會提供專業的意見，為產品改進提供專業建議，確保能生產高質量的產品。
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">Sampling</h3>
            <h3 v-show="this.$store.state.sw">製作樣品</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 100%" src="img/s4.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img src="img/s4.2.png" style="width: 100%" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            By ongoing testing and quality assurance, carrying out EU RoHS
            tests, MFI test and moisture tests, we can ensure out plastic
            material will be maintained at the highest of quality for production
          </div>
          <div class="right" v-show="this.$store.state.sw">
            我們會對樣品進行歐盟RoHS測試，MFI測試和水分測試，這樣可以確保所有的產品將保持高品質的生產。
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">lnspection</h3>
            <h3 v-show="this.$store.state.sw">檢測</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 115%" src="img/s5.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img style="width: 119%" src="img/s5.2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            We understand that our customers demand the highest standards for
            automatic inspection, we utilize special CCD mirror inspections to
            ensure that all products meet high-quality standards.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            我們瞭解客戶所想，對自動檢測，特殊CCD視鏡檢測的檢測要求很高，以確保所有產品符合高質量標準。
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">
              Automated Production and <br />
              Quality Assurance
            </h3>
            <h3 v-show="this.$store.state.sw">自動化生產及檢驗</h3>
            <div class="img">
              <div style="width: 60%">
                <img src="img/s6.1.png" style="width: 100%" alt="" />
              </div>
              <div style="width: 40%">
                <img src="img/s6.2.png" style="width: 150%" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            Across the lifecycle, from the selection of raw materials, to the
            automation of inspections, Kenta and our customers can be sure of
            accurate production plans and maintain the highest quality of
            products.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            從原材料選擇到後期檢驗的自動化，讓健大和我們的客戶獲得精確的生產計劃，保持高質量產品。
          </div>
        </div>
        <div>
          <div class="left">
            <h3 v-show="!this.$store.state.sw">
              Proactive After-sales Service
            </h3>
            <h3 v-show="this.$store.state.sw">高質量的售後服務</h3>
            <div class="img">
              <div style="width: 50%">
                <img style="width: 120%" src="img/s7.1.png" alt="" />
              </div>
              <div style="width: 50%">
                <img style="width: 120%" src="img/s7.2.png" alt="" />
              </div>
            </div>
          </div>
          <div class="right" v-show="!this.$store.state.sw">
            Our relationship and service do not end at shipping; our engineers
            maintain communication with our customers and continue to make
            suggestions for improvement, to provide ongoing efficiency uplift.
          </div>
          <div class="right" v-show="this.$store.state.sw">
            產品完成出貨程式後不是意味著與合作夥伴關係的結束。我們的工程師會向客戶保持溝通，有需要時會提出改進建議，形成雙贏局面。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sw: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less" scoped>
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    .mask {
      z-index: 99999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 35%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-weight: 800;
        font-family: Roboto Condensed;
        letter-spacing: 0.05em;
        text-transform: capitalize;
      }
    }
  }
  .content {
    width: 70%;
    margin: 2vw auto;
    h2 {
      font-size: 2vw;
      margin-bottom: 5vw;
    }
    > div {
      .img {
        width: 18vw;
        height: 6vw;
        margin: auto;
        padding-top: 0.5vw;
        display: flex;
        justify-content: space-between;
        > div {
          overflow: hidden;
          height: 100%;
        }
      }
      .left {
        text-align: center;
      }
      padding: 1vw 0vw 3vw 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .left {
        width: 50%;
        font-size: 1.5vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
      .right {
        color: grey;
        width: 60%;
        font-size: 1vw;
        font-family: Roboto;
        text-align: justify;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.05em;
      }
      border-top: 0.5vw solid;
      border-image: linear-gradient(to right, #743ad5 0%, #d53a9d 100%);
      border-image-slice: 1;
      border-width: 0.4vw;
      margin: 0;
      padding: 2vw;
      counter-increment: section;
      position: relative;
      &:before {
        content: counter(section);
        position: absolute;
        border-radius: 50%;
        padding: 1vw;
        height: 1.25em;
        width: 1.25em;
        background-color: #34435e;
        text-align: center;
        line-height: 1.25em;
        color: #ffffff;
        font-size: 1vw;
      }
    }
    > div:nth-child(odd) {
      border-right: 0.4vw solid;
      padding-left: 0;

      &:before {
        left: 100%;
        margin-left: -1.5vw;
      }
    }
    //even number borders
    > div:nth-child(even) {
      border-left: 0.4vw solid;
      padding-right: 0;

      &:before {
        right: 100%;
        margin-right: -1.5vw;
      }
    }
    //handle first and last
    > div:first-child {
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    > div:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .content5 {
    width: 80%;
    margin: auto;
    h2 {
      font-size: 8vw;
      margin: 8vw 0;
    }
    .left {
      font-size: 5vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      text-align: center;
    }
    .right {
      color: grey;
      font-size: 3.5vw;
      font-family: Roboto;
      text-align: justify;
      font-style: normal;
      font-weight: normal;
    }

    > div {
      .img {
        width: 60vw;
        height: 20vw;
        margin: auto;
        padding-top: 0.5vw;
        display: flex;
        justify-content: space-between;
        > div {
          overflow: hidden;
          height: 100%;
        }
      }
      padding: 1vw 0vw 3vw 0;
      width: 100%;
      border-top: 1vw solid;
      border-image: linear-gradient(to right, #743ad5 0%, #d53a9d 100%);
      border-image-slice: 1;
      border-width: 1vw;
      margin: 0;
      padding: 2vw;
      counter-increment: section;
      position: relative;
      &:before {
        content: counter(section);
        position: absolute;
        border-radius: 50%;
        padding: 1.5vw;
        height: 1.25em;
        width: 1.25em;
        background-color: #34435e;
        text-align: center;
        line-height: 1.25em;
        color: #ffffff;
        font-size: 5vw;
      }
    }
    > div:nth-child(odd) {
      border-right: 1vw solid;
      padding-left: 0;

      &:before {
        left: 100%;
        margin-left: -4vw;
      }
    }
    //even number borders
    > div:nth-child(even) {
      border-left: 1vw solid;
      padding-right: 0;

      &:before {
        right: 100%;
        margin-right: -4vw;
      }
    }
    //handle first and last
    > div:first-child {
      border-top: 0;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }
    > div:last-child {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>