<template>
  <div class="quality">
    <div class="pc">
      <div class="banner">
        <el-carousel trigger="click" height="34vw" :interval="5000">
          <el-carousel-item>
            <img class="img1" src="img/zbj.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img2" src="img/zbj2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img3" src="img/zbj3.png" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">
            Quality Assurance
          </div>
          <div class="box" v-show="this.$store.state.sw">質量</div>
        </div>
      </div>
      <div class="content">
        <br />
        <br />
        <p v-show="!this.$store.state.sw">
          Kenta is primarily focused on quality, no matter what the service is,
          we undertake our <br />
          operations to international standards and acoreditation.
        </p>
        <p v-show="this.$store.state.sw">
          健大企業十分重視質量，不論是服務上，產品上或是運作上。為了確保我們所提供的質量達致國際標準及讓我們客戶得到最好的服務，健大企業於過去獲得不同的國際認證。
        </p>
        <div class="box">
          <div>
            <div class="top"><img src="img/b1.svg" alt="" /></div>
            <div class="line"></div>
            <div class="module1" v-show="!this.$store.state.sw">
              Kenta holds lSO 9001 Quality Management.The lSO 9001 family
              addresses various aspects of quality management and contains some
              of ISO's best known standards.
            </div>
            <div class="module1" v-show="this.$store.state.sw">
              健大拥有ISO 9001质量管理体系。ISO
              9001系列涉及质量管理的各个方面，并包含一些 ISO
              最著名的标准。职责。
            </div>
            <br />
            <br /><img
              class="imgd"
              style="height: 12vw"
              src="img/t2.png"
              alt=""
            />
          </div>
          <div>
            <div class="top"><img src="img/b2.svg" alt="" /></div>
            <div class="line"></div>
            <div class="module1" v-show="!this.$store.state.sw">
              Kenta holds lSO 14001. Environment Management.The ISO 14001 family
              of standards provides practical tools for companies and
              organizations of all kinds looking to manage their environmental
              responsibilities.
            </div>
            <div class="module1" v-show="this.$store.state.sw">
              ISO 14001——環境管理體系是世界上首個國際環境標準，
              獲得了全球認可，説明健大在減少環境影響，促進業務增長的同時，取得可持續發展的平衡性。
              ISO 14001
              制定了最有效的方法來實施成功的環境管理體系(EMS)。這標準説明健大在保持商業上的成功同時不會忽視其環境責任，來增強公司生存力。
            </div>
            <br />
            <img class="imgd" style="height: 12vw" src="img/pz2.jpg" alt="" />
          </div>

          <div>
            <div class="top">
              <div class="top"><img src="img/b3.svg" alt="" /></div>
            </div>
            <div class="line"></div>
            <div class="module1" v-show="!this.$store.state.sw">
              Kenta holds and follows ISO/IATF 16949, this is an internationally
              recognized Quality Management System specification for the
              automotive industry.
            </div>
            <div class="module1" v-show="this.$store.state.sw">
              IATF16949是對汽車生產和相關配件組織應用的特殊要求，認證確保每個汽配產品的各個階段按照行業規定之法規法則管理，確保所有的產出均具有高品質。
            </div>
            <br />
            <br /><img
              class="imgd"
              style="height: 12vw"
              src="img/pz3.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">
            Quality Assurance
          </div>
          <div class="box" v-show="this.$store.state.sw">質量</div>
        </div>
      </div>
      <div class="content5">
        <br />
        <p v-show="!this.$store.state.sw">
          Kenta is primarily focused on quality, no matter what the service is,
          we undertake our <br />
          operations to international standards and acoreditation.
        </p>
        <p v-show="this.$store.state.sw">
          健大企業十分重視質量，不論是服務上，產品上或是運作上。為了確保我們所提供的質量達致國際標準及讓我們客戶得到最好的服務，健大企業於過去獲得不同的國際認證。
        </p>
        <div class="box">
          <div>
            <div class="top"><img src="img/b1.svg" alt="" /></div>
            <div class="line"></div>
            <div class="module1" v-show="!this.$store.state.sw">
              Kenta holds ISO 9001 Quality Management.The lSO 9001 family
              addresses various aspects of quality management and contains some
              of ISO's best known standards.
            </div>
            <div class="module1" v-show="this.$store.state.sw">
              健大拥有ISO 9001质量管理体系。ISO
              9001系列涉及质量管理的各个方面，并包含一些 ISO
              最著名的标准。职责。
            </div>
            <br />
            <img style="width: 100%" src="img/pz2.jpg" alt="" />
          </div>
          <div>
            <div class="top"><img src="img/b2.svg" alt="" /></div>
            <div class="line"></div>
            <div class="module1" v-show="!this.$store.state.sw">
              Kenta holds lSO 14001. Environment Management.The ISO 14001 family
              of standards provides practical tools for companies and
              organizations of all kinds looking to manage their environmental
              responsibilities.
            </div>
            <div class="module1" v-show="this.$store.state.sw">
              ISO 14001——環境管理體系是世界上首個國際環境標準，
              獲得了全球認可，説明健大在減少環境影響，促進業務增長的同時，取得可持續發展的平衡性。
              ISO 14001
              制定了最有效的方法來實施成功的環境管理體系(EMS)。這標準説明健大在保持商業上的成功同時不會忽視其環境責任，來增強公司生存力。
            </div>
            <img style="width: 100%" src="img/t2.png" alt="" />
          </div>

          <div>
            <div class="top"><img src="img/b3.svg" alt="" /></div>
            <div class="line"></div>
            <div class="module1" v-show="!this.$store.state.sw">
              Kenta holds and follows ISO/IATF 16949, this is an internationally
              recognized Quality Management System specification for the
              automotive industry.
            </div>
            <div class="module1" v-show="this.$store.state.sw">
              IATF16949是對汽車生產和相關配件組織應用的特殊要求，認證確保每個汽配產品的各個階段按照行業規定之法規法則管理，確保所有的產出均具有高品質。
            </div>
            <br /><img style="width: 100%" src="img/pz3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sw: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less" scoped>
.pc {
  font-family: Noto Sans TC;
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;

    .mask {
      z-index: 999999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-weight: 800;
        font-family: Roboto Condensed;
        letter-spacing: 0.05em;
        text-transform: capitalize;
      }
    }
  }
  .content {
    width: 70%;
    margin: auto;
    > h2 {
      margin: 2vw 0;
      font-size: 2vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.05em;
      text-align: justify;
    }
    > p {
      font-size: 1.5vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.05em;
    }
    .box {
      margin: 2vw 0;
      background: white;
      padding-bottom: 14vw;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .imgd {
        position: absolute;
        width: 17vw;
        height: 12vw;
        bottom: -10vw;
      }
      > div {
        width: 33%;
        display: inline-block;
        font-size: 1vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        box-sizing: border-box;
        position: relative;
        padding: 2vw 3vw;
        color: gray;
        vertical-align: top;
        .top {
          text-align: center;
          height: 30%;
          img {
            width: 80%;
          }
        }
        .line {
          width: 100%;
          height: 0.15vw;
          background: red;
          margin: 2vw 0;
        }
      }
    }
  }
}
.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .content5 {
    width: 90%;
    margin: auto;
    margin-bottom: 20vw;
    h2 {
      font-size: 8vw;
      text-align: center;
    }
    > p {
      margin: 8vw 0;
      text-align: center;
      font-size: 5vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.05em;
    }
    .box {
      background: white;
      padding: 5%;
      > div {
        margin-bottom: 10vw;
        .top {
          text-align: center;
          img {
            width: 60%;
            margin: 10vw 0;
          }
        }
        .line {
          background: red;
          height: 0.6vw;
          width: 100%;
          margin-bottom: 5vw;
        }
        .module1 {
          font-family: Roboto;
          text-align: justify;
          font-style: normal;
          font-weight: normal;
          color: #636264;
          font-size: 3.5vw;
        }
      }
    }
  }
}
</style>