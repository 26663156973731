<template>
  <div class="message">
    <div class="pc">
      <div class="banner">
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Notice</div>
          <div class="box" v-show="this.$store.state.sw">公告</div>
        </div>
      </div>
      <div class="content">

        <div class="box">
          <h3 class="time">
              2022年6月15日
              <div class="btn2" @click="sw4 = !sw4">
              <span v-show="!this.$store.state.sw"> Read More </span
              ><span v-show="this.$store.state.sw"> 閱讀更多</span>
              <img :class="sw4 ? 'on' : 'te'" src="img/j.png" alt="" />
            </div>
            </h3>
            <h1>
            健大精密电子（山东）有限公司
            <br />
            健大精密电子精密塑料零部件制造项目（一期）
          </h1>
          <br />
          <div v-show="sw4">
            <h1 style="text-align:center">项目调试公示</h1>
            <div class="boxx">
              <div>
                <div class="ge"></div>
                2022年6月15日，健大精密电子（山东）有限公司健大精密电子精密塑料零部件
                制造项目(一期）主体工程及配套防治污染的环保设施同步竣工，并于2022年6月
                15日开始进行污染防治设施的调试试运行，调试试运行起止时间为2022年6月15
                日至2022年9月15日。
              </div>
             </div>
             <h3 class="boxt">
              健大精密电子（山东）有限公司 <br />
              2022年6月15日
            </h3>
          </div> 
        </div>

        <div class="box">
          <h3 class="time">
            2022年6月6日
            <div class="btn2" @click="sw3 = !sw3">
              <span v-show="!this.$store.state.sw"> Read More </span
              ><span v-show="this.$store.state.sw"> 閱讀更多</span>
              <img :class="sw3 ? 'on' : 'te'" src="img/j.png" alt="" />
            </div>
          </h3>
          <h1>
            健大精密电子（山东）有限公司
            <br />
            健大精密电子精密塑料零部件制造项目（一期）
          </h1>
          <br />
          <div v-show="sw3">
            <h1 style="text-align:center">项目竣工公示</h1>
            <div class="boxx">
              <div>
                <div class="ge"></div>
                健大精密电子（山东）有限公司健大精密电子精密塑料零部件制造项目于2022年2月14日由潍坊
                市生态环境局新分局予以批复，批复文号：潍环高审字[2022]0204号。一期项目自2022年2月
                20日开工建设，截止到2022年5月30日一期工程建设完成，项目主体工程及配套防治污染的环保
                设施同步竣工，一期项目建设基本情况如下:
              </div>
            </div>

            <div class="boxx">
                <div>
                <div class="ge"></div>
                建设单位:健大精密电子（山东）有限公司
              </div>
              <div>
                <div class="ge"></div>
                环评单位:潍坊依科环境技术服务有限公司
              </div>
              </div>

            <div class="boxx">
                <div>
                <div class="ge"></div>
                建设内容:健大精密电子(山东〉有限公司在山东省潍坊市高新区清池街道高新五路以西，樱前街以
                北航空航天产业园10号厂房和7号厂房，投资10000万元建设“健大精密电子精密塑料零部件制造项
                目(一期)”。项目租赁航空航天产业园10号厂房和7号厂房进行建设，建筑面积15466方米。新购置
                100～1000吨不同类型注塑机等生产设备共计164套，建成后可形成年加工5000万套精密电子精密
                塑料零部件的生产能力(不涉及电镀工艺，不使用胶粘剂，不以再生塑料为原料)。
              </div>
            </div>
            <h3 class="boxt">
              健大精密电子（山东）有限公司 <br />
              2022年6月6日
            </h3>
          </div>
        </div>

        <div class="box">
          <h3 class="time">
            2021年11月15日
            <div class="btn2" @click="sw1 = !sw1">
              <span v-show="!this.$store.state.sw"> Read More </span
              ><span v-show="this.$store.state.sw"> 閱讀更多</span>
              <img :class="sw1 ? 'on' : 'te'" src="img/j.png" alt="" />
            </div>
          </h3>
          <h1>
            健大精密电子（山东）有限公司
            <br />
            健大精密电子精密塑料零部件制造项目第二次信息公示内容
          </h1>
          <br />
          <div v-show="sw1">
            <div class="boxx">
              <h2>1.建设项目的名称及概要</h2>
              <div>
                <div class="ge"></div>
                建设单位：健大精密电子（山东）有限公司
              </div>
              <div>
                <div class="ge"></div>
                项目名称：健大精密电子精密塑料零部件制造项目
              </div>
              <div>
                <div class="ge"></div>
                建设性质：新建
              </div>
              <div>
                <div class="ge"></div>
                建设地点：高新区航空航天产业园10号厂房
              </div>
              <div>
                <div class="ge"></div>
                建设内容：租赁高新区航空航天产业园10号厂房，总建筑面积8345平方米。
              </div>
              <div>
                项目拟购置100～1000吨不同类型注塑机，进行各类型精密塑料零部件生产。
              </div>
              <div>
                项目建成后，可实现不同类别产品年产量5000套的生产能力。
              </div>
            </div>
            <div class="boxx">
              <h2>2.建设项目的建设单位的名称和联系方式</h2>
              <div>
                <div class="ge"></div>
                建设单位：健大精密电子（山东）有限公司
              </div>
              <div>
                <div class="ge"></div>
                联系人：杨济宇
              </div>
              <div>
                <div class="ge"></div>
                电话：18562608721 邮箱：webb.yang@kenta.com.cn
              </div>
            </div>
            <div class="boxx">
              <h2>3.承担评价工作的环境影响评价机构的名称</h2>
              <div>
                <div class="ge"></div>
                环评单位：潍坊依科环境技术服务有限公司
              </div>
              <div>
                <div class="ge"></div>
                联系人：刘工
              </div>
              <div>
                <div class="ge"></div>
                电话：15165646762 邮箱ykhj2021@126.com
              </div>
            </div>
            <div class="boxx">
              <h2>4.环境影响评价报告表征求意见稿及众意见调查表</h2>
              <div>
                <div class="ge"></div>
                见附1，见附件2。
              </div>
            </div>
            <div class="boxx">
              <h2>5.公众提出意见的方式和途径</h2>
              <div>
                <div class="ge"></div>
                本次公告自发布之日起10个工作日结束，即2021年11月15日-11月26
                <br />
                日。公众可在该时间期限内，通过填写公众意见表，向公示指定地址发送信函、
                <br />
                传真、电子邮件等方式，发表对本项目及环评工作的意见看法。
              </div>
            </div>
            <h3 class="boxt">
              健大精密电子（山东）有限公司 <br />
              2021年11月15日
            </h3>
          </div>
        </div>
        <div class="box">
          <h3 class="time">
            2021年11月1日
            <div class="btn2" @click="sw2 = !sw2">
              <span v-show="!this.$store.state.sw"> Read More </span
              ><span v-show="this.$store.state.sw"> 閱讀更多</span>
              <img :class="sw2 ? 'on' : 'te'" src="img/j.png" alt="" />
            </div>
          </h3>
          <h1>
            健大精密电子（山东）有限公司
            <br />
            健大精密电子精密塑料零部件制造项目第一次信息公示内容
          </h1>
          <br />
          <div v-show="sw2">
            <div class="boxx">
              <h2>1.建设项目的名称及概要</h2>
              <div>
                <div class="ge"></div>
                建设单位：健大精密电子（山东）有限公司
              </div>
              <div>
                <div class="ge"></div>
                项目名称：健大精密电子精密塑料零部件制造项目
              </div>
              <div>
                <div class="ge"></div>
                建设性质：新建
              </div>
              <div>
                <div class="ge"></div>
                建设地点：高新区航空航天产业园10号厂房
              </div>
              <div>
                <div class="ge"></div>
                建设内容：租赁高新区航空航天产业园10号厂房，总建筑面积8345平方米。
              </div>
              <div>
                项目拟购置100～1000吨不同类型注塑机，进行各类型精密塑料零部件生产。
              </div>
              <div>
                项目建成后，可实现不同类别产品年产量5000套的生产能力。
              </div>
            </div>
            <div class="boxx">
              <h2>2.建设项目的建设单位的名称和联系方式</h2>
              <div>
                <div class="ge"></div>
                建设单位：健大精密电子（山东）有限公司
              </div>
              <div>
                <div class="ge"></div>
                联系人：杨济宇
              </div>
              <div>
                <div class="ge"></div>
                电话：18562608721 邮箱：webb.yang@kenta.com.cn
              </div>
            </div>
            <div class="boxx">
              <h2>3.承担评价工作的环境影响评价机构的名称</h2>
              <div>
                <div class="ge"></div>
                环评单位：潍坊依科环境技术服务有限公司
              </div>
              <div>
                <div class="ge"></div>
                联系人：刘工
              </div>
              <div>
                <div class="ge"></div>
                电话：15165646762 邮箱ykhj2021@126.com
              </div>
            </div>
            <div class="boxx">
              <h2>4.公众意见表</h2>
              <div>
                <div class="ge"></div>
                见链接：<a
                  href="http://www.mee.gov.cn/xxgk2018/xxgk/xxgk01/201810/t20181024_665329.html"
                  >http://www.mee.gov.cn/xxgk2018/xxgk/xxgk01/201810/t20181024_665329.html</a
                >
              </div>
            </div>
            <div class="boxx">
              <h2>5.公众提出意见的方式和途径</h2>
              <div>
                <div class="ge"></div>
                本次征求意见时间自发布日起10个工作日内，即2021年11月1日-11月
                12日。
                <br />公众可在该时间期限内，通过填写公众意见表，向公示指定地址发送信
                <br />
                函、传真、电子邮件等方式，发表对本项目及环评工作的意见看法。
              </div>
            </div>
            <h3 class="boxt">
              健大精密电子（山东）有限公司 <br />
              2021年11月1日
            </h3>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sw1: false,
      sw2: false,
      sw3: false,
      sw4: false,
    };
  },
};
</script>

<style lang="less" scoped>
.time {
  position: absolute;
  right: 2.5vw;
}
.ge {
  display: inline-block;
  width: 2vw;
}
.te {
  transform: rotateZ(180deg);
}
.on {
  transform: rotateZ(0deg);
}
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;

    .mask {
      z-index: 999999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-weight: 800;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
      }
    }
  }
  .content {
    .btn2 {
      margin: 1vw 0;
      cursor: pointer;
      img {
        transition: all 0.3s;
        width: 1.5vw;
      }
      &:hover {
        color: red;
      }
    }
    .box {
      text-align: center;
      padding: 3vw 0;
      position: relative;
      h1 {
        font-size: 1.8vw;
      }
      h2 {
        font-size: 1.3vw;
      }
      > div {
        font-size: 1vw;
      }
      .boxx {
        width: 45%;
        margin: 1vw auto;
        text-align: left;
      }
      .boxt {
        width: 45%;
        margin: 1vw auto;
        text-align: right;
      }
    }
    & > div:nth-of-type(even) {
      background: white;
      .left {
        img {
          position: relative;
          vertical-align: middle;
        }
      }
    }

    > div {
      text-align: justify;
      width: 100%;
    }
  }
}
</style>
