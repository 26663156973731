<template>
  <div class="MarKets">
    <div class="pc">
      <div class="banner">
        <el-carousel trigger="click" height="34vw" :interval="5000">
          <el-carousel-item>
            <img class="img1" src="img/zbj.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img2" src="img/zbj2.png" alt="" />
          </el-carousel-item>
          <el-carousel-item>
            <img class="img3" src="img/zbj3.png" alt="" />
          </el-carousel-item>
        </el-carousel>
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Markets</div>
          <div class="box" v-show="this.$store.state.sw">服務</div>
        </div>
      </div>
      <div class="content">
        <div>
          <br />
          <br />
          <div class="box" name="tab1" id="Automotive">
            <div class="left l1">
              <br />
              <br />
              <div v-show="!this.$store.state.sw" class="title">
                <span>Auto</span>motive
              </div>
              <div v-show="this.$store.state.sw" class="title">
                <span>汽配</span>車產品
              </div>
              <p v-show="!this.$store.state.sw">
                With more than a decade experience in automotive field, Kenta
                offers a total solution, lifecycle approach, to ensure time,
                stability, quality and cost effective solutions for the
                automotive industry, some examples of past success are, control
                module, ignition core, water/oil pumps and many other precision
                parts, as well as integration to a wide range of mechanical
                micro parts for high functionality even in the most precise
                devices.
              </p>
              <p v-show="this.$store.state.sw">
                憑藉在汽車領域的二十多年經驗，健大為汽配部件客戶提供了全面的解決方案，確保質量穩定及具有成本效益--由較大形的一系列性項目：方向盤，點火線圈以及水/油泵等精密部件，到高效能的微注塑件等等都能為客戶提供最優設計及生產。
                健大是世界上多間知名OEM和一級汽車供應商的主要生產供應商。各項完備的資源及基礎設施讓我們可以專注於汽車市場的重要要求-零缺陷要求，從而降低整體成本。
              </p>
              <p v-show="this.t1 && !this.$store.state.sw">
                Kenta has been a key supplier to some of the most well known
                automotive OEMs and tier 1 suppliers in the world. Kenta’s
                infrastructure of complete resources allows our clients to focus
                on what’s most important to the automotive market, zero defects,
                and lowering overall costs Total solutions included advisory
                services, including material selection and construction, DFM to
                simulate imperfections and failures, pilot development including
                prototypes tooling or 3D printing samples, mold trials involving
                scientific DOE to find ideal parameters and last but not least
                measurement protocols according to technical specifications.
                Kenta is a specialist for standard and non-standard types of
                injection molding including Precision injection molding, over
                molding, insert molding, multi-component injection molding and
                micro-technology. Kenta is also familiar with high temperature
                and glass filled fiber resin characteristics.
              </p>
              <p v-show="this.t1 && this.$store.state.sw">
                總體解決方案包括諮詢服務---材料和工序選擇、DFM模擬缺陷、試驗性開發---包括工程軟模具或3D打印樣品、運用科學注塑試模DOE找出的各項最佳參數及以跟據技術規格建議的測量方法。
                健大是標準和非標準注塑的專家，包括精密注塑，包胶注塑，嵌件模具，多部件注塑和微型技術。同時，健大對高溫處理及玻璃纖維樹脂的特點也十分熟悉。
              </p>
              <div class="btn2" @click="this.t11">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t1 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
            <div class="right">
              <img class="ps2" src="img/图片2-1.png" alt="" />
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab2" id="Medical">
            <div class="left ps" style="overflow: hidden; height: 20vw">
              <img
                style="width: 95%; position: relative; top: -10vw"
                src="img/图片3 2.png"
                alt=""
              />
            </div>
            <div class="right">
              <br />
              <br />
              <br />
              <div class="title" v-show="!this.$store.state.sw">
                <span>Medi</span>cal Device
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>醫療</span>產品
              </div>
              <p v-show="!this.$store.state.sw">
                Kenta has experience with producing different medical parts like
                scalpels, surgical knives and disposable testing devices. Kenta
                manufactures and assembles according to the high standards for
                this sector. The results are products of the highest quality
                according Installation Qualification DQ,IQ,OQ and PQ.
              </p>
              <p v-show="this.t2 && !this.$store.state.sw">
                Kenta closely works with customers and their medical products
                from conceptual designs, tool design , product engineering
                through to prototyping, automatic production as well as
                assembly. Kenta has refined its capabilities to successfully
                support our customers’ projects that demand a wide range of
                value-added services, strong process controls, and the ability
                to meet strict regulatory requirements. Collaboration with our
                customers in all phases of a product life cycle (DFMEA, PFMEA,
                Control Plan and PPAP are fully used in our product development
                stage) results in the highest value solutions that reduce time
                to market, manage risk and control costs.
              </p>
              <p v-show="this.$store.state.sw">
                健大生產不同醫療塑膠部件如解剖刀，手術刀或一次性檢測裝置，依據該行業的高標準生產和組裝產品，並根據安裝認證DQ，IQ，OQ和PQ製造最高質量的產品。
                健大與客戶有緊密合作，從概念設計、工具設計、產品工程、原型設計、自動生產以及組裝。健大完善的技術，成功地支持客戶的項目開發。這些項目需要廣泛的增值服務，強大的流程控制以及符合嚴格法規的要求。
              </p>
              <p v-show="this.t2 && this.$store.state.sw">
                健大與客戶有緊密合作，從概念設計、工具設計、產品工程、原型設計、自動生產以及組裝。
                健大完善的技術，成功地支持客戶的項目開發。這些項目需要廣泛的增值服務，強大的流程控制以及符合嚴格法規的要求。我們在產品生命週期（DFMEA，PFMEA，控制計劃和PPAP皆用於我們的產品開發階段）的各個階段與客戶進行協作，從而達致最高價值醫療部件的一條龍解決方案，縮短上市時間，減低管理風險和控制成本。
              </p>
              <div class="btn2" @click="this.t12">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t2 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab3" id="Network">
            <div class="left">
              <br />
              <br />
              <br />
              <div class="title" v-show="!this.$store.state.sw">
                <span>Elect</span>rical and Telecommunication
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>網絡</span>通信產品
              </div>
              <p v-show="!this.$store.state.sw">
                Kenta specialized in pin insertion,insert molding and
                overmolding processes which is often involved in the production
                of electrical and telecom parts including bobbin, connector,
                housing, pin header, toroid base, socket, adaptor, antenna etc.
                Kenta has been a prime supplier to the electronic and precision
                electrical connector/telecom industries. We have produced a wide
                variety of connectors and related plastics parts with extremely
                tight tolerances for many of the largest electronics companies
                in the world. Kenta is a market leader in this area.
              </p>
              <p v-show="this.t3 && !this.$store.state.sw">
                ● Pin insertion: with over 30 years of pin insertion experience,
                together with our specialist pin insertion workshops equipped
                with over 60 machines, Kenta is one of the biggest pin insertion
                plastics part manufacturer in China. <br />
                ● Insert Molding: Our capabilities include the ability to mold
                in place various inserts, pins, screws, and other items to help
                eliminate secondary operations. <br />
                ● Overmolding: This process is done by utilizing different
                colors, materials and molded over existing components. Our
                electrical and telecom parts are widely used in all types of
                applications spanning different markets; automotive, medical,
                power switches, security, electronics and more.
              </p>
              <p v-show="this.$store.state.sw">
                Kenta專門從事插針(pin insertion)、包胶(insert
                molding)和嵌件(overmolding)注塑工藝，善於生產電子和網絡電訊零部件，包括線軸，連接器，外殼，排针，環形底座，插座，適配器，天線等。健大一直是精密電子連接器/電訊業的龍頭公司的主要供應商。我們對公差有非常嚴格的要求,
                為世界多間大型的電子公司生產了各種各樣的連接器和相關塑膠零部件。
              </p>
              <p v-show="this.t3 && this.$store.state.sw">
                ●
                插針：具有超過30年的插針經驗，以及配有60台插針機器，健大是中國最具規模的插針塑膠零部件製造商之一。<br />
                ● 包胶注塑(insert molding)：我們能於模上嵌入不同的物件,如:針,
                螺釘或其他物品，從而減省工序。<br />
                ●
                嵌件注塑(overmolding)：是指通過使用不同的顏色，材料和現有組件重叠注塑。我們的廣泛應用於跨越行業的市場：汽車，醫療，電源開關，保安系統及電子等。
                <br />
              </p>
              <div class="btn2" @click="this.t13">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t3 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
            <div class="right ps">
              <img
                style="width: 100% !important"
                src="img/图片2 3.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab4" id="Industrial">
            <div class="left ps">
              <img class="ps2" src="img/图片2 4.png" alt="" />
            </div>
            <div class="right">
              <br />
              <br />
              <br />
              <br />
              <div class="title" v-show="!this.$store.state.sw">
                <span>lndu</span>strial
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>工控</span>產品
              </div>
              <p v-show="!this.$store.state.sw">
                Kenta has a long history of providing precision injection
                molding solutions to the Industrial market. With the advancement
                in thermoplastic resin materials, more metal components utilized
                in the industrial markets are being converted to plastic parts.
                Weight reductions, cost savings and improved lead-times are
                added benefits when switching from metal to plastic parts.
              </p>
              <p v-show="this.t4 && !this.$store.state.sw">
                Kenta understand customers in the Industrial market often face
                demanding applications for their products. Whether they have
                extreme operating temperatures, harsh chemicals or high impact
                applications, Industrial customers need a molder who is an
                expert in processing engineered thermoplastics. Kenta provides
                product design assistance for these challenging plastic
                injection molding parts.
                <br />
              </p>

              <p v-show="this.$store.state.sw">
                健大在工業市場提供精密注塑解決方案方面已有悠久的歷史。隨著熱塑性樹脂材料的進步，在工業市場中，大量的金屬部件正快速向輕量化並轉用塑膠零部件代替。從金屬零部件切換為塑膠零部件時，可達致減輕重量，節省成本和提高交貨期等好處。
              </p>
              <p v-show="this.t4 && this.$store.state.sw">
                健大了解工業市場的客戶往往面臨著他們的產品於苛刻惡劣的環境應用，如：高溫下，化學品下，或是高衝擊下應用，以致工業市場的客戶需要一位在熱塑性塑料加工的專家。
                健大能為這些具有挑戰性的注塑件提供產品設計及協作。
                <br />
              </p>
              <div class="btn2" @click="this.t14">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t4 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab5" id="Molding">
            <div class="left">
              <br />
              <br />
              <br />
              <div v-show="!this.$store.state.sw" class="title">
                <span>Ther</span>moset Molding
              </div>
              <div v-show="this.$store.state.sw" class="title">
                <span>電木</span>注塑
              </div>
              <p v-show="!this.$store.state.sw">
                At Kenta, we specialize in custom thermoset molding through
                compression and transfer molding processes. The combination of
                these materials and our thermoset molding processes allows us to
                manufacture components that are heat resistant, corrosion
                resistant, have low outgassing, can be formed into a variety of
                shapes and sizes, and have high strength characteristics.
              </p>
              <p v-show="this.$store.state.sw">
                Kenta擁有30多年的電木注塑經驗－－從設計、模具製造到生產，以工程和商品級樹脂原料生產出數百萬億個熱固性(電木)零件。具有耐熱性，耐腐蝕性，低放氣性的熱固性電木料可以形成各種形狀和尺寸，並且具有高強度特性，對於高機械強度、高溫度使用範圍和獨特的介電性能有著理想的應用
              </p>
              <br />
              <br />
            </div>
            <div class="right ps">
              <img src="img/图片2-5.png" alt="" />
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab6" id="Food">
            <div class="left ps">
              <br />
              <img
                class="ps2"
                style="width: 60%"
                src="img/图片2-6.png"
                alt=""
              />
            </div>
            <div class="right">
              <br />
              <br />
              <div class="title" v-show="!this.$store.state.sw">
                <span>Food</span> Grade
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>食品級</span>
              </div>

              <p v-show="!this.$store.state.sw">
                Materials that will come into direct contact with food must be
                durable, non-reactive, and inert. For consumer safety, it is
                vital that these plastics must be able to withstand factors such
                as chemical exposure, extreme temperatures, and UV radiation
                without breaking down. To be considered food-grade, plastic
                injection molding materials must adhere to strict regulations.
                Choosing the appropriate material for your food or beverage
                application helps ensure customer safety and satisfaction. At
                Kenta, our fully automated facility enables us to efficiently
                produce high-volume runs without sacrificing quality.
              </p>
              <p v-show="this.t6 && !this.$store.state.sw">
                We are often chosen by top food and beverage providers to
                manufacture food-grade plastic injection molded parts. We’ve
                done work on food packaging and processing applications, such
                has processing equipment components, and conveyor system
                components.
              </p>

              <p v-show="this.$store.state.sw">
                與食物直接接觸的材料必須是耐用的，無反應性的和惰性的了消費者安全，至關重要的是這些塑料必須能夠承受諸如化露，極端溫度和紫外線輻射等因素而不會分解。被認為是塑料注模材料必須遵守嚴格的規定。為您的食品或飲料應合適的材料有助於確保客戶的安全和滿意度。在健大，自動設備使我們能夠在不犧牲質量的情況下有效地進行大批產。
              </p>
              <p v-show="this.t6 && this.$store.state.sw">
                頂級食品和飲料供應商經常選擇我們來製造食品級塑料注射成型零件。
                我們已經完成了食品包裝和加工應用的工作，例如加工設備組件和輸送機系統組件。
              </p>
              <div class="btn2" @click="this.t16">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t6 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Markets</div>
          <div class="box" v-show="this.$store.state.sw">服務</div>
        </div>
      </div>
      <div class="content5">
        <div>
          <div class="box" name="tab1" id="tab1">
            <div class="right ps">
              <img style="width: 100%" src="img/图片2-1.png" alt="" />
            </div>
            <div class="left l1">
              <div v-show="!this.$store.state.sw" class="title">
                <span>Auto</span>motive
              </div>
              <div v-show="this.$store.state.sw" class="title">
                <span>汽配</span>車產品
              </div>
              <p v-show="!this.$store.state.sw">
                With more than a decade experience in automotive field, Kenta
                offers a total solution, lifecycle approach, to ensure time,
                stability, quality and cost effective solutions for the
                automotive industry, some examples of past success are, control
                module, ignition core, water/oil pumps and many other precision
                parts, as well as integration to a wide range of mechanical
                micro parts for high functionality even in the most precise
                devices.
              </p>
              <p v-show="this.$store.state.sw">
                憑藉在汽車領域的二十多年經驗，健大為汽配部件客戶提供了全面的解決方案，確保質量穩定及具有成本效益--由較大形的一系列性項目：方向盤，點火線圈以及水/油泵等精密部件，到高效能的微注塑件等等都能為客戶提供最優設計及生產。
                健大是世界上多間知名OEM和一級汽車供應商的主要生產供應商。各項完備的資源及基礎設施讓我們可以專注於汽車市場的重要要求-零缺陷要求，從而降低整體成本。
              </p>
              <p v-show="this.t1 && !this.$store.state.sw">
                Kenta has been a key supplier to some of the most well known
                automotive OEMs and tier 1 suppliers in the world. Kenta’s
                infrastructure of complete resources allows our clients to focus
                on what’s most important to the automotive market, zero defects,
                and lowering overall costs Total solutions included advisory
                services, including material selection and construction, DFM to
                simulate imperfections and failures, pilot development including
                prototypes tooling or 3D printing samples, mold trials involving
                scientific DOE to find ideal parameters and last but not least
                measurement protocols according to technical specifications.
                Kenta is a specialist for standard and non-standard types of
                injection molding including Precision injection molding, over
                molding, insert molding, multi-component injection molding and
                micro-technology. Kenta is also familiar with high temperature
                and glass filled fiber resin characteristics.
              </p>
              <p v-show="this.t1 && this.$store.state.sw">
                總體解決方案包括諮詢服務---材料和工序選擇、DFM模擬缺陷、試驗性開發---包括工程軟模具或3D打印樣品、運用科學注塑試模DOE找出的各項最佳參數及以跟據技術規格建議的測量方法。
                健大是標準和非標準注塑的專家，包括精密注塑，包胶注塑，嵌件模具，多部件注塑和微型技術。同時，健大對高溫處理及玻璃纖維樹脂的特點也十分熟悉。
              </p>
              <div class="btn2" @click="this.t11">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t1 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab2" id="tab2">
            <div class="left ps">
              <img class="ps3" style="width: 90%" src="img/ts.png" alt="" />
            </div>
            <div class="right">
              <div class="title" v-show="!this.$store.state.sw">
                <span>Medi</span>cal Device
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>醫療</span>產品
              </div>
              <p v-show="!this.$store.state.sw">
                Kenta has experience with producing different medical parts like
                scalpels, surgical knives and disposable testing devices. Kenta
                manufactures and assembles according to the high standards for
                this sector. The results are products of the highest quality
                according Installation Qualification DQ,IQ,OQ and PQ.
              </p>
              <p v-show="this.t2 && !this.$store.state.sw">
                Kenta closely works with customers and their medical products
                from conceptual designs, tool design , product engineering
                through to prototyping, automatic production as well as
                assembly. Kenta has refined its capabilities to successfully
                support our customers’ projects that demand a wide range of
                value-added services, strong process controls, and the ability
                to meet strict regulatory requirements. Collaboration with our
                customers in all phases of a product life cycle (DFMEA, PFMEA,
                Control Plan and PPAP are fully used in our product development
                stage) results in the highest value solutions that reduce time
                to market, manage risk and control costs.
              </p>
              <p v-show="this.$store.state.sw">
                健大生產不同醫療塑膠部件如解剖刀，手術刀或一次性檢測裝置，依據該行業的高標準生產和組裝產品，並根據安裝認證DQ，IQ，OQ和PQ製造最高質量的產品。
                健大與客戶有緊密合作，從概念設計、工具設計、產品工程、原型設計、自動生產以及組裝。健大完善的技術，成功地支持客戶的項目開發。這些項目需要廣泛的增值服務，強大的流程控制以及符合嚴格法規的要求。
              </p>
              <p v-show="this.t2 && this.$store.state.sw">
                健大與客戶有緊密合作，從概念設計、工具設計、產品工程、原型設計、自動生產以及組裝。
                健大完善的技術，成功地支持客戶的項目開發。這些項目需要廣泛的增值服務，強大的流程控制以及符合嚴格法規的要求。我們在產品生命週期（DFMEA，PFMEA，控制計劃和PPAP皆用於我們的產品開發階段）的各個階段與客戶進行協作，從而達致最高價值醫療部件的一條龍解決方案，縮短上市時間，減低管理風險和控制成本。
              </p>
              <div class="btn2" @click="this.t12">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t2 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab3" id="tab3">
            <div class="right ps">
              <img
                style="width: 100%; position: relative; top: -10%"
                src="img/图片2 3.png"
                alt=""
              />
            </div>
            <div class="left">
              <div class="title" v-show="!this.$store.state.sw">
                Electrical and Telecommunication
              </div>
              <div class="title" v-show="this.$store.state.sw">
                網絡通信產品
              </div>
              <div class="ine"></div>
              <p v-show="!this.$store.state.sw">
                Kenta specialized in pin insertion,insert molding and
                overmolding processes which is often involved in the production
                of electrical and telecom parts including bobbin, connector,
                housing, pin header, toroid base, socket, adaptor, antenna etc.
                Kenta has been a prime supplier to the electronic and precision
                electrical connector/telecom industries. We have produced a wide
                variety of connectors and related plastics parts with extremely
                tight tolerances for many of the largest electronics companies
                in the world. Kenta is a market leader in this area.
              </p>
              <p v-show="this.t3 && !this.$store.state.sw">
                ● Pin insertion: with over 30 years of pin insertion experience,
                together with our specialist pin insertion workshops equipped
                with over 60 machines, Kenta is one of the biggest pin insertion
                plastics part manufacturer in China. <br />
                ● Insert Molding: Our capabilities include the ability to mold
                in place various inserts, pins, screws, and other items to help
                eliminate secondary operations. <br />
                ● Overmolding: This process is done by utilizing different
                colors, materials and molded over existing components. Our
                electrical and telecom parts are widely used in all types of
                applications spanning different markets; automotive, medical,
                power switches, security, electronics and more.
              </p>
              <p v-show="this.$store.state.sw">
                Kenta專門從事插針(pin insertion)、包胶(insert
                molding)和嵌件(overmolding)注塑工藝，善於生產電子和網絡電訊零部件，包括線軸，連接器，外殼，排针，環形底座，插座，適配器，天線等。健大一直是精密電子連接器/電訊業的龍頭公司的主要供應商。我們對公差有非常嚴格的要求,
                為世界多間大型的電子公司生產了各種各樣的連接器和相關塑膠零部件。
              </p>
              <p v-show="this.t3 && this.$store.state.sw">
                ●
                插針：具有超過30年的插針經驗，以及配有60台插針機器，健大是中國最具規模的插針塑膠零部件製造商之一。<br />
                ● 包胶注塑(insert molding)：我們能於模上嵌入不同的物件,如:針,
                螺釘或其他物品，從而減省工序。<br />
                ●
                嵌件注塑(overmolding)：是指通過使用不同的顏色，材料和現有組件重叠注塑。我們的廣泛應用於跨越行業的市場：汽車，醫療，電源開關，保安系統及電子等。
              </p>
              <div class="btn2" @click="this.t13">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t3 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab4" id="tab4">
            <div class="left ps" style="">
              <img
                class="ps2"
                style="width: 100%"
                src="img/图片2 4.png"
                alt=""
              />
            </div>
            <div class="right">
              <div class="title" v-show="!this.$store.state.sw">
                <span>lndu</span>strial
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>工控</span>產品
              </div>
              <p v-show="!this.$store.state.sw">
                Kenta has a long history of providing precision injection
                molding solutions to the Industrial market. With the advancement
                in thermoplastic resin materials, more metal components utilized
                in the industrial markets are being converted to plastic parts.
                Weight reductions, cost savings and improved lead-times are
                added benefits when switching from metal to plastic parts.
              </p>
              <p v-show="this.t4 && !this.$store.state.sw">
                Kenta understand customers in the Industrial market often face
                demanding applications for their products. Whether they have
                extreme operating temperatures, harsh chemicals or high impact
                applications, Industrial customers need a molder who is an
                expert in processing engineered thermoplastics. Kenta provides
                product design assistance for these challenging plastic
                injection molding parts.
                <br />
              </p>

              <p v-show="this.$store.state.sw">
                健大在工業市場提供精密注塑解決方案方面已有悠久的歷史。隨著熱塑性樹脂材料的進步，在工業市場中，大量的金屬部件正快速向輕量化並轉用塑膠零部件代替。從金屬零部件切換為塑膠零部件時，可達致減輕重量，節省成本和提高交貨期等好處。
              </p>
              <p v-show="this.t4 && this.$store.state.sw">
                健大了解工業市場的客戶往往面臨著他們的產品於苛刻惡劣的環境應用，如：高溫下，化學品下，或是高衝擊下應用，以致工業市場的客戶需要一位在熱塑性塑料加工的專家。
                健大能為這些具有挑戰性的注塑件提供產品設計及協作。
                <br />
              </p>
              <div class="btn2" @click="this.t14">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t4 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab5" id="tab5">
            <div class="right ps">
              <img
                style="position: relative; top: -10%; width: 90%"
                src="img/图片2-5.png"
                alt=""
              />
            </div>
            <div class="left">
              <div v-show="!this.$store.state.sw" class="title">
                <span>Ther</span>moset Molding
              </div>
              <div v-show="this.$store.state.sw" class="title">
                <span>電木</span>注塑
              </div>
              <p v-show="!this.$store.state.sw">
                At Kenta, we specialize in custom thermoset molding through
                compression and transfer molding processes. The combination of
                these materials and our thermoset molding processes allows us to
                manufacture components that are heat resistant, corrosion
                resistant, have low outgassing, can be formed into a variety of
                shapes and sizes, and have high strength characteristics.
              </p>
              <p v-show="this.$store.state.sw">
                Kenta擁有30多年的電木注塑經驗－－從設計、模具製造到生產，以工程和商品級樹脂原料生產出數百萬億個熱固性(電木)零件。具有耐熱性，耐腐蝕性，低放氣性的熱固性電木料可以形成各種形狀和尺寸，並且具有高強度特性，對於高機械強度、高溫度使用範圍和獨特的介電性能有著理想的應用
              </p>
            </div>
          </div>
        </div>
        <div>
          <div class="box" name="tab6" id="tab6">
            <div class="left ps">
              <img src="img/图片2-6.png" />
            </div>
            <div class="right">
              <div class="title" v-show="!this.$store.state.sw">
                <span>Food</span> Grade
              </div>
              <div class="title" v-show="this.$store.state.sw">
                <span>食品級</span>
              </div>

              <p v-show="!this.$store.state.sw">
                Materials that will come into direct contact with food must
                <br />
                be durable, non-reactive, and inert. For consumer safety, it
                <br />
                is vital that these plastics must be able to withstand factors
                such as chemical exposure, extreme temperatures, and UV
                radiation without breaking down. To be considered food-grade,
                plastic injection molding materials must adhere to strict
                regulations. Choosing the appropriate material for your food or
                beverage application helps ensure customer safety and
                satisfaction. At Kenta, our fully automated facility enables us
                to efficiently produce high-volume runs without sacrificing
                quality.
              </p>
              <p v-show="this.t6 && !this.$store.state.sw">
                We are often chosen by top food and beverage providers to
                manufacture food-grade plastic injection molded parts. We’ve
                done work on food packaging and processing applications, such
                has processing equipment components, and conveyor system
                components.
              </p>

              <p v-show="this.$store.state.sw">
                與食物直接接觸的材料必須是耐用的，無反應性的和惰性的了消費者安全，至關重要的是這些塑料必須能夠承受諸如化露，極端溫度和紫外線輻射等因素而不會分解。被認為是塑料注模材料必須遵守嚴格的規定。為您的食品或飲料應合適的材料有助於確保客戶的安全和滿意度。在健大，自動設備使我們能夠在不犧牲質量的情況下有效地進行大批產。
              </p>
              <p v-show="this.t6 && this.$store.state.sw">
                頂級食品和飲料供應商經常選擇我們來製造食品級塑料注射成型零件。
                我們已經完成了食品包裝和加工應用的工作，例如加工設備組件和輸送機系統組件。
              </p>
              <div class="btn2" @click="this.t16">
                <span v-show="!this.$store.state.sw"> Read More </span
                ><span v-show="this.$store.state.sw"> 閱讀更多</span>
                <img :class="this.t6 ? 'on' : 'te'" src="img/j.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {
      sw: false,
      t1: false,
      t2: false,
      t3: false,
      t4: false,
      t5: false,
      t6: false,
    };
  },
  mounted() {
    var hash = window.location.hash;
    var index = hash.lastIndexOf("#");
    if (index != -1) {
      var id = hash.substring(index + 1, hash.length + 1);
      var div = document.getElementById(id);
      if (div) {
        //$('html,body').scrollTop($(div).offset().top - 43);
        $("html, body").animate({ scrollTop: $(div).offset().top - 43 }, 500);
      }
    } else {
      window.scrollTo(0, 0);
    }
  },
  methods: {
    t11() {
      this.t1 = !this.t1;
    },
    t12() {
      this.t2 = !this.t2;
    },
    t13() {
      this.t3 = !this.t3;
    },
    t14() {
      this.t4 = !this.t4;
    },
    t15() {
      this.t5 = !this.t5;
    },
    t16() {
      this.t6 = !this.t6;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100% !important;
}
.te {
  transform: rotateZ(180deg);
}
.on {
  transform: rotateZ(0deg);
}
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;

    .mask {
      z-index: 999999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-weight: 800;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
      }
    }
  }
  .content {
    h2 {
      font-size: 2vw;
      width: 70%;
      margin: 2vw auto;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      margin-bottom: 0vw;
      padding-left: 0.5vw;
    }
    width: 100%;

    & > div:nth-of-type(even) {
      background: white;
      .left {
        img {
          position: relative;
          vertical-align: middle;
        }
      }
    }

    > div {
      text-align: justify;
      width: 100%;
    }
    .box {
      width: 70%;
      display: flex;
      justify-content: space-between;
      margin: auto;
      .ps {
        // text-align: center;
        // padding: 2.5vw;
      }
      .ps2 {
        // position: relative;
        // top: -10vw;
      }

      .title {
        color: black;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 1.3vw;
        letter-spacing: 0.05em;
        margin-bottom: 1.5vw;
        span {
          border-bottom: red solid 0.2vw;
          padding-bottom: 0.5vw;
        }
      }
      > div {
        p {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          letter-spacing: 0.05em;
        }
        .btn2 {
          margin: 1vw 0;
          cursor: pointer;
          img {
            transition: all 0.3s;
            width: 1.5vw;
          }
          &:hover {
            color: red;
          }
        }
        width: 45%;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        letter-spacing: 0.05em;
        font-size: 1vw;
        padding: 0.5vw;
        color: gray;
        img {
          width: 100%;
        }
      }
    }
  }
}

.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .content5 {
    h2 {
      font-size: 8vw;
      padding: 0 8%;
      margin: 15vw 0;
    }
    img {
      width: 80%;
      margin: auto;
    }
    .ps {
      width: 100%;
      text-align: center;
      img {
        width: 70%;
      }
    }
    > div {
      padding: 0 5%;
      overflow: hidden;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      color: #686b71;
      padding: 10vw;
      letter-spacing: 0.05em;
      font-size: 3.5vw;
      .ine {
        width: 15vw;
        background: red;
        height: 0.5vw;
        position: relative;
        top: -5vw;
      }
      .title {
        color: black;
        font-size: 6vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        margin-bottom: 8vw;
        span {
          border-bottom: 0.5vw solid red;
          padding-bottom: 3vw;
        }
      }
      .left {
        box-sizing: border-box;
        overflow: hidden;
      }
      .btn2 {
        margin: 1vw 0;
        cursor: pointer;
        img {
          transition: all 0.3s;
          width: 5vw;
        }
        &:hover {
          color: red;
        }
      }
    }
    & > div:nth-of-type(even) {
      background: white;
    }
  }
}
</style>