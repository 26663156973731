<template>
  <div class="contact">
    <div class="pc">
      <div class="banner">
        <div class="banner">
          <div class="mask">
            <div class="box" v-show="!this.$store.state.sw">Contact Us</div>
            <div class="box" v-show="this.$store.state.sw">聯絡我們</div>
          </div>
        </div>
      </div>
      <div class="box">
        <h3 class="title" v-show="!this.$store.state.sw">
          <span>Enq</span>uiry
        </h3>
        <h3 class="title" v-show="this.$store.state.sw"><span>查詢</span></h3>
        <p v-show="!this.$store.state.sw">
          lf you have any enquiries, you can contact customer service via email
          to learn about the company's products and services and request a
          quotation
        </p>
        <p v-show="this.$store.state.sw">
          如有任何查詢，可通過電郵聯絡客服，以了解公司的產品及服務索取報價
        </p>
      </div>
      <div class="module1">
        <div class="box">
          <div class="top">
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="!this.$store.state.sw ? 'Contact Person' : '姓名'"
                v-model="input1"
                clearable
              />
            </div>
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="
                  !this.$store.state.sw ? 'Company Name' : '公司名稱'
                "
                v-model="input2"
                clearable
              />
            </div>
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="!this.$store.state.sw ? 'Email' : '電郵地址'"
                v-model="input3"
                clearable
              />
            </div>
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="!this.$store.state.sw ? 'Tel.no' : '聯絡電話'"
                v-model="input4"
                clearable
              />
            </div>
          </div>
          <div class="bottom">
            <textarea
              class="textarea2"
              rows="10"
              cols="10"
              :placeholder="!this.$store.state.sw ? 'Message' : '訊息'"
            ></textarea>
          </div>
          <div class="btn3" @click="this.submit">
            <a style="color: white">
              <span v-show="!this.$store.state.sw">Send</span>
              <span v-show="this.$store.state.sw">送出</span></a
            >
          </div>
        </div>
      </div>
      <div class="module2">
        <div class="content3">
          <div v-show="!this.$store.state.sw">
            <h3 class="title"><span>Hong</span> Kong</h3>
            <p>
              Flat B&C,11/F Winner Factory Building,55 Hung to Road, Kwun Tong
              Kowloon, Hong Kong
            </p>
            <br />

            <p>FAX</p>
            <p>(852)2343 3650</p>
            <p></p>
            <br />
            <p>TEL</p>
            <p>(852)2341 1597</p>
            <p>(852)2344 7465</p>
            <br />
            <p>EMAIL</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@22.3090627,114.2284574,17z/data=!4m5!3m4!1s0x34040145ec7a9a0d:0x4fee46a4e5117e3!8m2!3d22.3107291!4d114.2223934"
              >Google Map</a
            >
            <br />
            <a
              href="https://map.baidu.com/poi/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12716489.383092336,2532944.0728554637,19.29z,15.2t,358.74h?uid=d0c88622fda8805e9122e09c&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl"
              >Baidu Map</a
            >
          </div>
          <div v-show="this.$store.state.sw">
            <h3 class="title">
              <span>香港</span>
            </h3>
            <p>
              香港九龍官塘鴻圖道55號 <br />
              幸運工業大廈11樓B&C室
            </p>
            <br />
            <p>傳真</p>
            <p>+852 2343 3650</p>
            <p></p>
            <br />
            <p>電話</p>
            <p>+852 2341 1597</p>
            <p>+852 2344 7465</p>
            <br />
            <p>電子郵件</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@22.3090627,114.2284574,17z/data=!4m5!3m4!1s0x34040145ec7a9a0d:0x4fee46a4e5117e3!8m2!3d22.3107291!4d114.2223934"
              >谷歌 地圖</a
            >
            <br />
            <a
              href="https://map.baidu.com/poi/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12716489.383092336,2532944.0728554637,19.29z,15.2t,358.74h?uid=d0c88622fda8805e9122e09c&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl"
              >百度 地圖</a
            >
          </div>
          <div v-show="!this.$store.state.sw">
            <h3 class="title"><span>Dong</span>guan</h3>
            <p>
              He Xi District,Jin Xia,Cheung On,Dongguan City,Guangdong China
            </p>
            <br />
            <p>FAX</p>
            <p>(+86 0769)8553 3429</p>
            <p>(+86 0769)8531 7199</p>
            <br />
            <p>TEL</p>
            <p>(+86 0769)8553 6899</p>
            <p>(+86 0769)8553 6999</p>
            <br />
            <p>EMAIL</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@22.7929116,113.7983921,20z/data=!4m5!3m4!1s0x340397b2a647749f:0x63a87acf3a8f925f!8m2!3d22.792807!4d113.798776"
              >Google Map</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@12668836.805,2590994,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82&c=119&src=0&wd2=%E4%B8%9C%E8%8E%9E%E5%B8%82&pn=0&sug=1&l=19&b=(12668361.56017,2590747.933;12669321.62983,2591216.467)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ce65c2d5d2277592c4733a1&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >Baidu Map</a
            >
          </div>
          <div v-show="this.$store.state.sw">
            <h3 class="title">
              <span>東莞</span>
            </h3>
            <p>
              中國廣東省東莞市 <br />
              長安鎮 錦廈河西工業區
            </p>
            <br />
            <p>傳真</p>
            <p>+86 0769 8553 3429</p>
            <p>+86 0769 8531 7199</p>
            <p></p>
            <p>電話</p>
            <p>+86 0769 8553 6899</p>
            <p>+86 0769 8553 6999</p>
            <br />
            <p>電子郵件</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@22.7929116,113.7983921,20z/data=!4m5!3m4!1s0x340397b2a647749f:0x63a87acf3a8f925f!8m2!3d22.792807!4d113.798776"
              >谷歌 地圖</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@12668836.805,2590994,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82&c=119&src=0&wd2=%E4%B8%9C%E8%8E%9E%E5%B8%82&pn=0&sug=1&l=19&b=(12668361.56017,2590747.933;12669321.62983,2591216.467)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ce65c2d5d2277592c4733a1&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >百度 地圖</a
            >
          </div>
          <div v-show="!this.$store.state.sw">
            <h3 class="title"><span>Kun</span>shan</h3>
            <P>888 Xinmao Road, zhoushi Town, Kunshan City, Jiangsu China</P>
            <br />
            <br />
            <p>FAX</p>
            <p>(+86 0512) 5788 9999</p>
            <p></p>
            <br />
            <p>TEL</p>
            <p>(+86 0512) 5788 9889</p>
            <p></p>
            <br />
            <p>EMAIL</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@31.4637856,120.9665653,17z/data=!3m1!4b1!4m5!3m4!1s0x35b24bc919315a59:0x869bf7f803278f03!8m2!3d31.463781!4d120.968754"
              >Google Map</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@13466986.035,3671495.85,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=119&src=0&wd2=%E8%8B%8F%E5%B7%9E%E5%B8%82%E6%98%86%E5%B1%B1%E5%B8%82&pn=0&sug=1&l=19&b=(12668356.77017,2590759.733;12669316.83983,2591228.267)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ed20a150df94e9756bfff8f&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >Baidu Map</a
            >
          </div>
          <div v-show="this.$store.state.sw">
            <h3 class="title">
              <span>崑山</span>
            </h3>
            <p>
              中國江蘇省昆山市 <br />
              周市鎮鑫茂路888號
            </p>
            <br />
            <p>傳真</p>
            <p>+86 0512 5788 9999</p>
            <p></p>
            <br />
            <p>電話</p>
            <p>+86 0512 5788 9889</p>
            <p></p>
            <br />
            <p>電子郵件</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@31.4637856,120.9665653,17z/data=!3m1!4b1!4m5!3m4!1s0x35b24bc919315a59:0x869bf7f803278f03!8m2!3d31.463781!4d120.968754"
              >谷歌 地圖</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@13466986.035,3671495.85,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=119&src=0&wd2=%E8%8B%8F%E5%B7%9E%E5%B8%82%E6%98%86%E5%B1%B1%E5%B8%82&pn=0&sug=1&l=19&b=(12668356.77017,2590759.733;12669316.83983,2591228.267)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ed20a150df94e9756bfff8f&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >百度 地圖</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Contact Us</div>
          <div class="box" v-show="this.$store.state.sw">聯絡我們</div>
        </div>
      </div>
      <div class="box">
        <h3 class="title" v-show="!this.$store.state.sw">
          <span>Enq</span>uiry
        </h3>
        <h3 class="title" v-show="this.$store.state.sw"><span>查詢</span></h3>
        <p v-show="!this.$store.state.sw">
          lf you have any enquiries, you can contact customer service via email
          to learn about the company's products and services and request a
          quotation
        </p>
        <p v-show="this.$store.state.sw">
          如有任何查詢，可通過電郵聯絡客服，以了解公司的產品及服務索取報價
        </p>
      </div>
      <div class="module1">
        <div class="box">
          <div class="top">
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="!this.$store.state.sw ? 'Contact Person' : '姓名'"
                v-model="input1"
                clearable
              />
            </div>
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="
                  !this.$store.state.sw ? 'Company Name' : '公司名稱'
                "
                v-model="input2"
                clearable
              />
            </div>
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="!this.$store.state.sw ? 'Email' : '電郵地址'"
                v-model="input3"
                clearable
              />
            </div>
            <div class="demo-input-suffix">
              <input
                class="textarea"
                :placeholder="!this.$store.state.sw ? 'Tel.no' : '聯絡電話'"
                v-model="input4"
                clearable
              />
            </div>
          </div>
          <div class="bottom">
            <textarea
              class="textarea2"
              rows="10"
              cols="10"
              :placeholder="!this.$store.state.sw ? 'Message' : '訊息'"
            ></textarea>
          </div>
          <div class="btn3" @click="this.submit">
            <a style="color: white">
              <span v-show="!this.$store.state.sw">Send</span>
              <span v-show="this.$store.state.sw">送出</span></a
            >
          </div>
        </div>
      </div>
      <div class="module2">
        <div class="content3">
          <div v-show="!this.$store.state.sw">
            <h3 class="title"><span>Hong</span> Kong</h3>
            <p>Flat B&C,11/F Winner Factory</p>
            <p>Building,</p>
            <p>55 Hung to Road, Kwun Tong</p>
            <p>Kowloon, Hong Kong</p>
            <br />

            <p>FAX</p>
            <p>(852)2343 3650</p>
            <br />
            <p>TEL</p>
            <p>(852)2341 1597</p>
            <p>(852)2344 7465</p>
            <br />
            <p>EMAIL</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@22.3090627,114.2284574,17z/data=!4m5!3m4!1s0x34040145ec7a9a0d:0x4fee46a4e5117e3!8m2!3d22.3107291!4d114.2223934"
              >Google Map</a
            >
            <br />
            <a
              href="https://map.baidu.com/poi/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12716489.383092336,2532944.0728554637,19.29z,15.2t,358.74h?uid=d0c88622fda8805e9122e09c&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl"
              >Baidu Map</a
            >
          </div>
          <div v-show="this.$store.state.sw">
            <h3 class="title">
              <span>香港</span>
            </h3>
            <p>
              香港九龍官塘鴻圖道55號 <br />
              幸運工業大廈11樓B&C室
            </p>
            <br />

            <p>傳真</p>
            <p>+852 2343 3650</p>
            <br />
            <p>電話</p>
            <p>+852 2341 1597</p>
            <p>+852 2344 7465</p>
            <br />
            <p>電子郵件</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@22.3090627,114.2284574,17z/data=!4m5!3m4!1s0x34040145ec7a9a0d:0x4fee46a4e5117e3!8m2!3d22.3107291!4d114.2223934"
              >谷歌 地圖</a
            >
            <br />
            <a
              href="https://map.baidu.com/poi/%E5%81%A5%E5%A4%A7%E4%BC%81%E4%B8%9A%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@12716489.383092336,2532944.0728554637,19.29z,15.2t,358.74h?uid=d0c88622fda8805e9122e09c&ugc_type=3&ugc_ver=1&device_ratio=1&compat=1&querytype=detailConInfo&da_src=shareurl"
              >百度 地圖</a
            >
          </div>
          <div v-show="!this.$store.state.sw">
            <h3 class="title"><span>Dong</span>guan</h3>

            <p>He Xi District,Jin Xia,</p>
            <p>Cheung On,</p>
            <p>Dongguan City,</p>
            <p>Guangdong China</p>
            <br />
            <p>FAX</p>
            <p>(+86 0769)8553 3429</p>
            <p>(+86 0769)8531 7199</p>
            <br /><br />
            <p>TEL</p>
            <p>(+86 0769)8553 6899</p>
            <p>(+86 0769)8553 6999</p>
            <br />
            <p>EMAIL</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@22.7929116,113.7983921,20z/data=!4m5!3m4!1s0x340397b2a647749f:0x63a87acf3a8f925f!8m2!3d22.792807!4d113.798776"
              >Google Map</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@12668836.805,2590994,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82&c=119&src=0&wd2=%E4%B8%9C%E8%8E%9E%E5%B8%82&pn=0&sug=1&l=19&b=(12668361.56017,2590747.933;12669321.62983,2591216.467)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ce65c2d5d2277592c4733a1&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >Baidu Map</a
            >
          </div>
          <div v-show="this.$store.state.sw">
            <h3 class="title">
              <span>東莞</span>
            </h3>
            <p>
              中國廣東省東莞市長安鎮 <br />
              錦廈河西工業區
            </p>
            <br />
            <p>傳真</p>
            <p>+86 0769 8553 3429</p>
            <p>+86 0769 8531 7199</p>
            <br /><br />
            <p>電話</p>
            <p>+86 0769 8553 6899</p>
            <p>+86 0769 8553 6999</p>
            <br />
            <p>電子郵件</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@22.7929116,113.7983921,20z/data=!4m5!3m4!1s0x340397b2a647749f:0x63a87acf3a8f925f!8m2!3d22.792807!4d113.798776"
              >谷歌 地圖</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82/@12668836.805,2590994,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E9%94%A6%E5%8E%A6%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E5%8E%82&c=119&src=0&wd2=%E4%B8%9C%E8%8E%9E%E5%B8%82&pn=0&sug=1&l=19&b=(12668361.56017,2590747.933;12669321.62983,2591216.467)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ce65c2d5d2277592c4733a1&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >百度 地圖</a
            >
          </div>
          <div v-show="!this.$store.state.sw">
            <h3 class="title"><span>Kun</span>shan</h3>
            <P>888 Xinmao Road, </P>
            <p>zhoushi Town,</p>
            <p>Kunshan City,</p>
            <p>Jiangsu China</p>
            <br />
            <br />
            <p>FAX</p>
            <p>(+86 0512) 5788 9999</p>
            <br />
            <br />
            <p>TEL</p>
            <p>(+86 0512) 5788 9889</p>
            <br />
            <p>EMAIL</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@31.4637856,120.9665653,17z/data=!3m1!4b1!4m5!3m4!1s0x35b24bc919315a59:0x869bf7f803278f03!8m2!3d31.463781!4d120.968754"
              >Google Map</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@13466986.035,3671495.85,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=119&src=0&wd2=%E8%8B%8F%E5%B7%9E%E5%B8%82%E6%98%86%E5%B1%B1%E5%B8%82&pn=0&sug=1&l=19&b=(12668356.77017,2590759.733;12669316.83983,2591228.267)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ed20a150df94e9756bfff8f&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >Baidu Map</a
            >
          </div>
          <div v-show="this.$store.state.sw">
            <h3 class="title">
              <span>崑山</span>
            </h3>
            <p>
              中國江蘇省昆山市 <br />
              周市鎮鑫茂路888號
            </p>
            <br />
            <br />
            <p>傳真</p>
            <p>+86 0512 5788 9999</p>
            <br />
            <br />
            <p>電話</p>
            <p>+86 0512 5788 9889</p>
            <br />
            <p>電子郵件</p>
            <p>info@kenta.com.cn</p>
            <br />
            <a
              href="https://www.google.com/maps/place/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@31.4637856,120.9665653,17z/data=!3m1!4b1!4m5!3m4!1s0x35b24bc919315a59:0x869bf7f803278f03!8m2!3d31.463781!4d120.968754"
              >谷歌 地圖</a
            >
            <br />
            <a
              href="https://map.baidu.com/search/%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8/@13466986.035,3671495.85,19z,15.2t,358.74h?querytype=s&da_src=shareurl&wd=%E5%81%A5%E5%A4%A7%E7%94%B5%E4%B8%9A%E5%88%B6%E5%93%81(%E6%98%86%E5%B1%B1)%E6%9C%89%E9%99%90%E5%85%AC%E5%8F%B8&c=119&src=0&wd2=%E8%8B%8F%E5%B7%9E%E5%B8%82%E6%98%86%E5%B1%B1%E5%B8%82&pn=0&sug=1&l=19&b=(12668356.77017,2590759.733;12669316.83983,2591228.267)&from=webmap&biz_forward=%7B%22scaler%22:1,%22styles%22:%22pl%22%7D&sug_forward=0ed20a150df94e9756bfff8f&seckey=dea14b1662e03b69972ceb2e0acf82544980a86b4dff8293bcbc4b627e95ca4cef69e92d37e998942671319a8859d61e1b3c28f7a99199e9b5d33f8ce5ea0e9e65e890a92fb0f0b0b9c6b8164866ab06caf21449216c9ab21a75f298c607325ceb104a4292d1132e589150b8f8e24c26af1dc6e680ae3060a24422111dbd7151ee7ee8050d2d9c045318c7f281d4882c9d7c09ac9137212731ac9f40b2180678a8f8b8c1e1a05a0e03ebf1ac3644c77ab27cbc8dfb5e03c974ae7fa1def8c67f85646f998650c7d1d861183fa4dbe0f428603054cc538b92d38f87f20907fcdf82e20f432b764854591fb797dff84730&device_ratio=1"
              >百度 地圖</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    // 切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0);
  },
  data() {
    return {
      input1: "",
      input2: "",
      input3: "",
      input4: "",
      textarea: "",
      center: { lng: 114.233755, lat: 22.31423 },
      center2: { lng: 113.805082, lat: 22.798761 },
      center3: { lng: 120.974901, lat: 31.470526 },
      zoom: 16,
    };
  },
  methods: {
    submit() {
      this.axios
        .post("https://api22.kenta.cn:8841/email", {
          姓名: this.input1,
          公司名称: this.input2,
          电邮地址: this.input3,
          电话: this.input4,
          内容: this.textarea,
        })
        .then((res) => {
          //
          alert(res.data);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;

    .mask {
      z-index: 99999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
      }
    }
  }
  .box {
    width: 50%;
    margin: 1vw auto;
    h3 {
      font-size: 2vw;
      margin: 3vw 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.05em;
      span {
        border-bottom: red solid 0.2vw;
        padding: 1vw 0;
      }
    }
    > p {
      color: gray;
      font-size: 1vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.05em;
    }
  }
  .textarea {
    width: 100%;
    height: 3vw;
    font-size: 1vw;
    box-sizing: border-box;
    padding-left: 1vw;
    border: gray solid 1px;
  }
  .textarea2 {
    width: 100%;
    font-size: 1vw;
    border: gray solid 1px;
    box-sizing: border-box;
    padding: 1vw;
    font-family: Arial;
  }
  .module1 {
    .box {
      width: 50%;
      margin: 0 auto;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      align-items: center;
      letter-spacing: 0.05em;
      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .demo-input-suffix {
          width: 49%;
          margin-top: 1vw;
          .el-input__inner {
            font-size: 1vw !important;
            height: 120px impo !important;
            line-height: 20px im !important;
          }
        }
      }
      .bottom {
        width: 100%;
        margin: 1vw 0;
      }
    }
    .btn3 {
      color: white;
      background: black;
      text-align: center;
      width: 10%;
      padding: 0.8vw 1vw;
      font-size: 1.2vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      align-items: center;

      letter-spacing: 0.05em;
      margin-bottom: 1vw;
    }
  }
  .module2 {
    width: 100%;
    background: white;
    padding: 6vw 0;
    .content3 {
      margin: 0 auto;
      width: 50%;
      display: flex;
      justify-content: space-between;
      > div {
        width: 30%;
        h3 {
          font-size: 2vw;
          font-family: Roboto;
          font-style: normal;
          font-weight: 900;
          letter-spacing: 0.05em;
          span {
            padding: 1vw 0;
            border-bottom: red solid 0.2vw;
          }
          margin-bottom: 2vw;
        }
        p {
          font-style: normal;
          font-weight: normal;
          font-size: 0.9vw;
          letter-spacing: 0.05em;
          color: gray;
        }
        a {
          border-bottom: 1px solid gray !important;
        }
      }
      img {
        width: 110%;
      }
    }
  }
}
.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .textarea {
    width: 100%;
    height: 6vw;
    font-size: 3vw;
    box-sizing: border-box;
    padding-left: 1vw;
    border: gray solid 1px;
  }
  .textarea2 {
    width: 100%;
    font-size: 3vw;
    border: gray solid 1px;
    box-sizing: border-box;
    padding: 1vw;
    font-family: Arial;
  }
  > .box {
    width: 80%;
    margin: 1vw auto;
    h3 {
      font-size: 8vw;
      margin: 6vw 0;
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      letter-spacing: 0.05em;
      span {
        border-bottom: red solid 0.5vw;
        padding: 3vw 0;
      }
    }
    > p {
      color: gray;
      font-size: 3.5vw;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
    }
  }
  .module1 {
    width: 80%;
    margin: 10vw auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 3.5vw;
    * {
      margin: 1vw 0;
    }
    .btn3 {
      width: 30%;
      padding: 2vw;
      text-align: center;
      background: black;
      margin: auto;
    }
  }
  .module2 {
    padding: 5vw 0;
    background: white;
    a {
      font-size: 4vw !important;
      border-bottom: 1px solid #000 !important;
    }
    .content3 {
      h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        font-size: 8vw;
        margin: 6vw 0;
      }
      width: 80%;
      margin: 0 auto;
      > div {
        margin-top: 10vw;
        > div {
          margin: 10vw 0;
        }
      }
      span {
        padding: 2vw 0;
        border-bottom: red solid 0.5vw;
      }
      p {
        // font-family: Noto Sans TC;
        font-style: normal;
        font-weight: normal;
        font-size: 3.5vw;
        color: #636264;
        line-height: 8vw;
      }
    }
  }
}
</style>