<template>
  <div class="facilities">
    <div class="pc">
      <div class="banner">
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Facilities</div>
          <div class="box" v-show="this.$store.state.sw">設備</div>
        </div>
      </div>
      <div class="content">
        <div>
          <img src="img/模具製造.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Mold manufacturing</p>
          <p v-show="this.$store.state.sw">模具生產</p>
        </div>
        <div>
          <img src="img/注塑車間.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Plastic injection molding</p>
          <p v-show="this.$store.state.sw">塑膠注塑模具</p>
        </div>
        <div>
          <img src="img/cz.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Pin-insertion</p>
          <p v-show="this.$store.state.sw">插針裝配</p>
        </div>
        <div>
          <img src="img/cl.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Measurement</p>
          <p v-show="this.$store.state.sw">測量儀器</p>
        </div>
        <div>
          <img src="img/t2.png" alt="" />
          <p v-show="!this.$store.state.sw">Clean room</p>
          <p v-show="this.$store.state.sw">無塵工作間</p>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Facilities</div>
          <div class="box" v-show="this.$store.state.sw">設備</div>
        </div>
      </div>
      <div class="content5">
        <div>
          <img src="img/模具製造.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Mold manufacturing</p>
          <p v-show="this.$store.state.sw">模具生產</p>
        </div>
        <div>
          <img src="img/注塑車間.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Plastic injection molding</p>
          <p v-show="this.$store.state.sw">塑膠注塑模具</p>
        </div>
        <div>
          <img src="img/cz.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Pin-insertion</p>
          <p v-show="this.$store.state.sw">插針裝配</p>
        </div>
        <div>
          <img src="img/cl.jpg" alt="" />
          <p v-show="!this.$store.state.sw">Measurement</p>
          <p v-show="this.$store.state.sw">測量儀器</p>
        </div>
        <div>
          <img src="img/t2.png" alt="" />
          <p v-show="!this.$store.state.sw">Clean room</p>
          <p v-show="this.$store.state.sw">無塵工作間</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sw: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
 
<style lang="less" scoped>
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;

    .mask {
      z-index: 99999;
      position: absolute;
      top: 0;
      background: #a5a5a4;
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-weight: 800;
        font-family: Roboto Condensed;
        letter-spacing: 0.05em;
        text-transform: capitalize;
      }
    }
  }
  .content {
    width: 100% !important;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      background: white;
      height: 30vw;
      position: relative;
      overflow: hidden;
      img {
        width: 125%;
      }
      p {
        width: 100%;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.494);
        color: white;
        text-align: center;
        font-size: 2vw;
        padding: 0.5vw;
        font-family: Roboto;
        font-style: normal;
        font-weight: 900;
        letter-spacing: 0.05em;
      }
    }
    & > :nth-last-child(1) {
      width: 100%;
      background: red;
      img {
        position: relative;
        top: -10vw;
      }
    }
  }
}
.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .content5 {
    font-size: 0;
    > div {
      width: 100%;
      overflow: hidden;
      position: relative;
      p {
        width: 100%;
        color: white;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        padding: 2vw 0;
        background: rgba(0, 0, 0, 0.494);
        font-size: 5vw;
        position: absolute;
        bottom: 0;
      }
      img {
        width: 110%;
      }
    }
  }
}
</style>