<template>
  <div class="inner">
    <div class="pc">
      <div class="banner">
        <div class="banner">
          <img src="img/zs.png" style="width: 100%" alt="" />
          <div class="mask">
            <div class="box" v-show="!this.$store.state.sw">Innovation</div>
            <div class="box" v-show="this.$store.state.sw">創新</div>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="module1">
          <div class="left"><img src="img/zs.jpg" alt="" /></div>
          <div class="right">
            <img src="img/idea 8.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Patent</span> lnvention
            </h4>
            <h4 v-show="this.$store.state.sw"><span>專利</span>發明</h4>
            <p v-show="!this.$store.state.sw">
              Over time, Kenta has successfully apllied and been granted more
              than 75 Patents and lnnovations, including Stack Mold and Vaccuum
              Mold.
            </p>
            <p v-show="this.$store.state.sw">
              截至2021年，我們共擁有了超過8項專利發明
              和40項實用新型，包括疊模和真空模。
            </p>
          </div>
        </div>
        <div class="module2">
          <div class="left">
            <img src="img/steering-wheel 1.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Confo</span>rmal Cooling Channel
            </h4>
            <h4 v-show="this.$store.state.sw"><span>隨型</span>水路</h4>
            <p v-show="!this.$store.state.sw">
              KENTA has established a conformal cooling channel design team for
              since 2013, a laser sintering metal 3D printer was invested in
              2018 to produce conformal panel parts in house.The purpose is to
              shorten the cycle and improve quality problems such as shrinkage
              and deformation.
            </p>
            <p v-show="this.$store.state.sw">
              KENTA自2013
              年起就建立了一個專業的隨型冷流道研發團隊，根據客戶需求獨立設計，並2018
              年購入激光燒結金屬3D打印機自行制作隨型鑲件。目的為縮短週期、改善品質如縮水及變形等問題。
            </p>
          </div>
          <div class="right"><img src="img/图片3 1.png" alt="" /></div>
        </div>
        <div class="module3">
          <div class="left">
            <img src="img/mj2.jpg" alt="" /><br />
            <br />
            <br /><img src="img/mj1.jpg" alt="" />
          </div>
          <div class="right">
            <img src="img/block 2.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Precis</span>ion Plastic Mold
            </h4>
            <h4 v-show="this.$store.state.sw"><span>精密</span>注塑模具</h4>

            <h4 v-show="!this.$store.state.sw">Tooling design</h4>
            <h4 v-show="this.$store.state.sw">模具設計</h4>
            <p v-show="!this.$store.state.sw">
              our design team develops tooling design using software such as
              Mold Flow, to create the most reliable and optimized tool our
              design team develops tooling designusing software such as Mold
              Flow, to createthe most reliable and optimized tool structure, we
              develop our tools in discussion with our clients for continuous
              improvement. A comprehensive Design FEMA report can be provided to
              our customer for idea exchange and approval purpose.Also, the
              Kenta design team can provide concepts such as stack molding for
              larger volume parts or conformal cooling channels for challenging
              design.
            </p>
            <p v-show="this.$store.state.sw">
              健大的設計團隊使用模流分析，仿真模具和注塑成型流程並通過不斷的討論和改進，分析最切合及最優化的模具結構。同時，我們會為客戶提供全面的設計FEMA報告，從而達致全面的交流及審批。創新方面，健大的設計團隊能為客人處理具有挑戰性的設計。例如:就一些量大或復雜的部件或提供使用堆疊模具或随形冷却水道的建議等等。
            </p>
            <div id="123"></div>
            <h4 v-show="!this.$store.state.sw">Tooling fabrication</h4>
            <h4 v-show="this.$store.state.sw">模具制造</h4>
            <p v-show="!this.$store.state.sw">
              Our highly-specialized tooling department is staffed by highly
              skilled and competent toolmakers. Kenta has invested heavily and
              equipped our facilities with world’s class processing machines
              (Roders、Agie etc); we have constant temperature workshop
              environment, which guarantees quality and repeatability for the
              manufacturing & research of complicated and high precision
              injection tooling. Kenta have systemic tool making planning and a
              separate dimension measuring center located in tool shop, Kenta
              provides competitive lead-times for quick turnaround projects.
            </p>
            <p v-show="this.$store.state.sw">
              我們極專業的模具部門配備了高技術和素質的員工，為我們國內、外的模具客戶提供滿意的服務。
              健大備有世界一流的加工機器(德國羅德斯，瑞士阿奇等等)，恆溫控制車間，為複雜及高精度注塑模具製造和研究提供支持和保證。通過系統製定計劃和獨立尺寸測量中心，使健大為快速周轉的項目提供了競爭力的前置時間。
            </p>
          </div>
        </div>
        <div class="module4">
          <div class="left">
            <img src="img/system 2.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Produ</span>ction & Systems Management
            </h4>
            <h4 v-show="this.$store.state.sw"><span>系統</span>化管理</h4>
            <p v-show="!this.$store.state.sw">
              The recent trend in plastic production dictated by Industry 4.0
              demands is to acquire a great deal of data for manufacturing
              process control. Kenta has implemented a knowledge-based
              management system. Different systems of ERP, MES, PLM and WMS have
              been launched successively to promote digitalization. These allow
              us to fully understand the real time status at any stage through
              an engagement; we utilize the system to review past, current and
              predict future performance, which allows us to optimize each
              stage, guaranteeing repeatable, on time, best cost, quality
              products.
            </p>
            <p v-show="this.$store.state.sw">
              工業4.0潮流席捲全世界，為了讓健大在此衝擊下維持競爭力，健大大力推廣智慧製造轉型。在業務持續增長的同時，健大實踐以知識為本的管理系統。在日常營運中注入數碼化的概念，有計劃的按「工業4.0」藍圖邁進。各ERP,MES,PLM,WMS不同系統先後上線。自動地執行生產管理及調度以獲得更詳細的生產狀態，從而能更準確地預測訂單完成時間。
            </p>
          </div>
          <div class="right"><img src="img/gy4.0.jpg" alt="" /></div>
        </div>
      </div>
    </div>
    <div class="yd">
      <div class="banner">
        <img src="img/zbj.png" alt="" />
        <div class="mask">
          <div class="box" v-show="!this.$store.state.sw">Innovation</div>
          <div class="box" v-show="this.$store.state.sw">創新</div>
        </div>
      </div>
      <div class="content5">
        <div class="module1">
          <br><br><br>
          <div class="left"><img src="img/zs.jpg" alt="" /></div>
          <div class="right">
            <img src="img/idea 8.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Patent</span> lnvention
            </h4>
            <h4 v-show="this.$store.state.sw"><span>專利</span>發明</h4>
            <div></div>
            <p v-show="!this.$store.state.sw">
              Over time, Kenta has successfully apllied and been granted more
              than 75 Patents and lnnovations, including Stack Mold and Vaccuum
              Mold.
            </p>
            <p v-show="this.$store.state.sw">
              截至2021年，我們共擁有了超過8項專利發明
              和40項實用新型，包括疊模和真空模。
            </p>
          </div>
        </div>
        <div class="module2">
          <div class="right"><img src="img/图片3 1.png" alt="" /></div>
          <div class="left">
            <img src="img/steering-wheel 1.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Confo</span>rmal Cooling Channel
            </h4>
            <h4 v-show="this.$store.state.sw"><span>隨型</span>水路</h4>
            <div></div>
            <p v-show="!this.$store.state.sw">
              KENTA has established a conformal cooling channel design team for
              since 2013, a laser sintering metal 3D printer was invested in
              2018 to produce conformal panel parts in house.The purpose is to
              shorten the cycle and improve quality problems such as shrinkage
              and deformation.
            </p>
            <p v-show="this.$store.state.sw">
              KENTA自2013
              年起就建立了一個專業的隨型冷流道研發團隊，根據客戶需求獨立設計，並2018
              年購入激光燒結金屬3D打印機自行制作隨型鑲件。目的為縮短週期、改善品質如縮水及變形等問題。
            </p>
          </div>
        </div>
        <div class="module3">
          <div class="left"><img src="img/mj2.jpg" alt="" /></div>
          <div class="right">
            <img src="img/block 2.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Precis</span>ion Plastic Mold
            </h4>
            <h4 v-show="this.$store.state.sw"><span>精密</span>注塑模具</h4>
            <div class="biao"></div>
            <h4 v-show="!this.$store.state.sw">Tooling design</h4>
            <h4 v-show="this.$store.state.sw">模具設計</h4>
            <br />
            <p v-show="!this.$store.state.sw">
              our design team develops tooling design using software such as
              Mold Flow, to create the most reliable and optimized tool our
              design team develops tooling designusing software such as Mold
              Flow, to createthe most reliable and optimized tool structure, we
              develop our tools in discussion with our clients for continuous
              improvement. A comprehensive Design FEMA report can be provided to
              our customer for idea exchange and approval purpose.Also, the
              Kenta design team can provide concepts such as stack molding for
              larger volume parts or conformal cooling channels for challenging
              design.
            </p>
            <p v-show="this.$store.state.sw">
              健大的設計團隊使用模流分析，仿真模具和注塑成型流程並通過不斷的討論和改進，分析最切合及最優化的模具結構。同時，我們會為客戶提供全面的設計FEMA報告，從而達致全面的交流及審批。創新方面，健大的設計團隊能為客人處理具有挑戰性的設計。例如:就一些量大或復雜的部件或提供使用堆疊模具或随形冷却水道的建議等等。
            </p>
            <br />
            <br />
            <h4 v-show="!this.$store.state.sw">Tooling fabrication</h4>
            <h4 v-show="this.$store.state.sw">模具制造</h4>
            <br />
            <p v-show="!this.$store.state.sw">
              Our highly-specialized tooling department is staffed by highly
              skilled and competent toolmakers. Kenta has invested heavily and
              equipped our facilities with world’s class processing machines
              (Roders、Agie etc); we have constant temperature workshop
              environment, which guarantees quality and repeatability for the
              manufacturing & research of complicated and high precision
              injection tooling. Kenta have systemic tool making planning and a
              separate dimension measuring center located in tool shop, Kenta
              provides competitive lead-times for quick turnaround projects.
            </p>
            <p v-show="this.$store.state.sw">
              我們極專業的模具部門配備了高技術和素質的員工，為我們國內、外的模具客戶提供滿意的服務。
              健大備有世界一流的加工機器(德國羅德斯，瑞士阿奇等等)，恆溫控制車間，為複雜及高精度注塑模具製造和研究提供支持和保證。通過系統製定計劃和獨立尺寸測量中心，使健大為快速周轉的項目提供了競爭力的前置時間。
            </p>
          </div>
          <br />
          <br />
        </div>
        <div class="module4">
          <div class="right"><img src="img/gy4.0.jpg" alt="" /></div>
          <div class="left">
            <img src="img/system 2.png" alt="" />
            <h4 v-show="!this.$store.state.sw">
              <span>Produ</span>ction & Systems Management
            </h4>
            <h4 v-show="this.$store.state.sw"><span>系統</span>化管理</h4>
            <div class="biao"></div>
            <p v-show="!this.$store.state.sw">
              The recent trend in plastic production dictated by Industry 4.0
              demands is to acquire a great deal of data for manufacturing
              process control. Kenta has implemented a knowledge-based
              management system. Different systems of ERP, MES, PLM and WMS have
              been launched successively to promote digitalization. These allow
              us to fully understand the real time status at any stage through
              an engagement; we utilize the system to review past, current and
              predict future performance, which allows us to optimize each
              stage, guaranteeing repeatable, on time, best cost, quality
              products.
            </p>
            <p v-show="this.$store.state.sw">
              工業4.0潮流席捲全世界，為了讓健大在此衝擊下維持競爭力，健大大力推廣智慧製造轉型。在業務持續增長的同時，健大實踐以知識為本的管理系統。在日常營運中注入數碼化的概念，有計劃的按「工業4.0」藍圖邁進。各ERP,MES,PLM,WMS不同系統先後上線。自動地執行生產管理及調度以獲得更詳細的生產狀態，從而能更準確地預測訂單完成時間。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="less" scoped>
.pc {
  .banner {
    height: 12vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    .mask {
      z-index: 9999;
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.301);
      width: 100%;
      height: 100%;
      .box {
        position: absolute;
        bottom: 0;
        width: 50%;
        left: 0;
        top: 4vw;
        right: 0;
        margin: auto;
        color: white;
        font-size: 4vw;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #ffffff;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.25);
      }
    }
  }
  h3 {
    width: 100%;
    margin: 2vw auto;
    font-size: 2vw;
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    letter-spacing: 0.05em;
    color: #18191b;
  }
  h4 {
    font-size: 1.5vw;
    margin: 2vw 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.05em;
    span {
      border-bottom: red solid 0.2vw;
      padding-bottom: 1vw;
    }
  }
  .content {
    width: 70%;
    margin: 0 auto;
    > div {
      display: flex;
      justify-content: space-between;
      margin: 3vw 0;
      > div {
        width: 45%;
        overflow: hidden;
        p {
          text-align: justify;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          letter-spacing: 0.05em;
          color: gray;
          font-size: 1vw;
        }
      }
    }
    .module1 {
      height: 20vw;
      position: relative;
      .left {
        overflow: hidden;
        text-align: center;
        img {
          bottom: 0;
          left: 0;
          width: 100%;
        }
      }
      .right {
        box-sizing: border-box;
        img {
          width: 15%;
        }
      }
    }
    .module2 {
      .left {
        width: 45%;

        img {
          width: 10%;
        }
      }
      .right {
        text-align: center;
        img {
          width: 110%;
        }
      }
    }
    .module3 {
      .left {
        width: 45%;
        text-align: left;
        img {
          width: 125%;
        }
      }
      .right {
        box-sizing: border-box;
        width: 45%;
        img {
          width: 15%;
        }
        padding: 0vw 0vw;
      }
    }
    .module4 {
      .left {
        width: 45%;
        img {
          width: 15%;
        }
        vertical-align: bottom;
      }
      .right {
        width: 45%;
        overflow: hidden;
        vertical-align: bottom;
        img {
          width: 130%;
          position: relative;
          left: -7vw;
        }
      }
    }
  }
}
.yd {
  .banner {
    height: 80vw;
    overflow: hidden;
    text-align: center;
    position: relative;
    > img {
      width: 300%;
      position: relative;
      top: -50vw;
      left: -100vw;
      z-index: 0;
    }
    .mask {
      position: absolute;
      top: 0;
      background: rgba(15, 15, 15, 0.394);
      width: 100%;
      height: 100%;
      .box {
        box-sizing: border-box;
        position: absolute;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        top: 0;
        right: 0;
        bottom: 0;

        font-size: 8vw;
        // font-family: Roboto Condensed;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-shadow: 5px 4px 10px rgba(0, 0, 0, 0.455);
      }
    }
  }
  .content5 {
    > h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: 900;
      font-size: 8vw;
      padding-left: 10%;
      margin: 5vw 0;
    }
    width: 100%;
    margin: auto;
    > div {
      width: 80%;
      margin: auto;
      text-align: center;
    }
    .module1 {
      text-align: center;
      .left {
        img {
          width: 100%;
        }
      }
      .right {
        color: #686b71;
        > div {
          background: red;
          width: 30%;
          height: 0.5vw;
          margin: 6vw auto;
        }
        img {
          width: 20%;
          margin: 10vw 0;
        }
        h4 {
          font-size: 5.5vw;
          color: black;
        }
        p {
          font-size: 3.5vw;
        }
      }
    }
    .module2 {
      margin-top: 15vw;

      .right {
        img {
          width: 90%;
        }
      }
      .left {
        img {
          width: 20%;
          margin: 10vw 0;
        }
        > div {
          background: red;
          width: 30%;
          height: 0.5vw;
          margin: 6vw auto;
        }
      }
      h4 {
        font-size: 5.5vw;
        color: black;
      }
      p {
        font-size: 3.5vw;
        color: #686b71;
      }
    }
    .module3 {
      .biao {
        background: red;
        width: 30%;
        height: 0.5vw;
        margin: 3vw auto;
      }
      .left {
        img {
          width: 100%;
        }
      }
      .right {
        img {
          width: 20%;
          margin: 10vw 0;
        }
        h4 {
          font-size: 5.5vw;
          color: black;
        }
        p {
          font-size: 3.5vw;
          color: #686b71;
        }
      }
    }
    .module4 {
      margin-bottom: 20vw;
      .biao {
        background: red;
        width: 30%;
        height: 0.5vw;
        margin: 3vw auto;
      }
      .left {
        img {
          width: 20%;
          margin: 10vw 0;
        }
        h4 {
          font-size: 5.5vw;
          color: black;
        }
        p {
          font-size: 3.5vw;
          color: #686b71;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          letter-spacing: 0.05em;
          line-height: 5vw;
        }
      }
      .right {
        img {
          width: 100%;
        }
      }
    }
  }
}
</style>